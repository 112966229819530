<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">
</head>
<div class="game-container">
  <canvas #renderCanvas touch-action="none"></canvas>
  <div id="gameOver" class="game-over" style="display: none;">
    <h2>Tạm dừng</h2>
    <ul>
      <li *ngFor="let problem of plansProblem">
        <span>{{ problem.name }}</span>
        <span class="container-status">
          <span *ngIf="problem.option == 5" class="dot-status red"></span>
          <span *ngIf="problem.option == 1" class="dot-status green"></span>
          <span *ngIf="problem.option == 3" class="dot-status yellow"></span>
          <span *ngIf="problem.option == 2" class="dot-status cyan"></span>
          <span *ngIf="problem.option == 4" class="dot-status pink"></span>
        </span>
      </li>
    </ul>
    <button (click)="replay()">Đánh giá lại</button>
    <button *ngIf="buttonFind" (click)="findDiamonds()">Xem hướng dẫn</button>
    <button *ngIf="!buttonFind" (click)="loadNextGuide()">Hướng dẫn khác</button>
  </div>
  <div id="diamonds">
    &#10084; {{ this.userData.diamonds }}
  </div>
  <div id="guideContainer" class="guides-container">
  </div>
  <button *ngIf="localVideoFrame" (click)="endVideoCall()" class="end-call-button">End Call</button>
  <video id="localVideo" #local_video autoplay [muted]="true" playsinline></video>
  <video id="remoteVideo" #remote_video autoplay playsinline></video>
</div>
<script>
  function resizeCanvas() {
    var canvas = document.querySelector('#renderCanvas');
    canvas.style.height = window.innerHeight + 'px';
  }

  window.addEventListener('resize', resizeCanvas);
  document.addEventListener('DOMContentLoaded', function() {
    resizeCanvas();
  });
</script>

