import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import * as GUI from 'babylonjs-gui';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
  DocumentChange,
  QueryDocumentSnapshot,
  DocumentData,
  DocumentReference,
  setDoc,
} from '@angular/fire/firestore';
import * as CANNON from 'cannon';
import { Subject } from 'rxjs';
import {
  Storage,
  ref,
  uploadBytesResumable,
  listAll,
  deleteObject,
  getDownloadURL,
} from '@angular/fire/storage';
import { NotifierService } from 'angular-notifier';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../../services/auth.service';

declare let webkitSpeechRecognition: any;

interface Problem {
  name: string;
  tag: string[];
}

interface Plan {
  providerEmail: string;
  plans: { name: string; option: number; tag: string[] }[];
  clientEmail: string;
  createdAt: any;
  clientPhone: string;
  fullName: string;
  guide?: string[];
  history?: string[];
  medications: { brandName: string; quantity: number; use: string }[];
}

interface DocumentGuide {
  document: QueryDocumentSnapshot<DocumentData>;
  guides: string[];
  providerEmail: string;
}

interface Property {
  propTag: string[];
  propName: string;
  classify: string[];
  // Thêm các trường khác nếu cần
}

interface UserDocument {
  gifters: Array<{ email: string; lastSent?: string; seen?: boolean }>;
  bannerUrl: string;
  organize: string;
  watching: string[];
  email: string;
  property: Property[];
  diamonds: number;
  avata: string;
  menu: string[];
  name: string;
  // Thêm các trường khác nếu cần
}

interface Product {
  classify: string[];
  propBenefit: string;
  propName: string;
  propPrice: number;
  propTag: string[];
  propTip: string;
}

interface CallData {
  offer?: RTCSessionDescriptionInit;
  answer?: RTCSessionDescriptionInit;
  candidates?: RTCIceCandidateInit[];
  providerEmail?: string;
  clientEmail?: string;
  status?: 'incoming' | 'accepted' | 'rejected' | 'ended';
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit, OnDestroy {
  @ViewChild('renderCanvas', { static: true })
  renderCanvas!: ElementRef<HTMLCanvasElement>;
  private gameEngine!: BABYLON.Engine;
  private gameScene!: BABYLON.Scene;
  private canvas!: HTMLCanvasElement;
  private player!: BABYLON.Mesh;
  private start!: BABYLON.Mesh;
  private stop!: BABYLON.Mesh;
  private coins: BABYLON.Mesh[] = [];
  public plansProblem: {
    tag: any;
    name: string;
    option: number;
  }[] = [];
  private gameOver: boolean = false;
  buttonFind: boolean = true;
  matchingProblems: Problem[] = [];
  pickedProblems: Set<string> = new Set();
  private guides: DocumentGuide[] = []; //Các guide từ các plans doc để show
  private currentDocumentIndex: number = 0;
  recognition: any;
  public transcriptText = '';
  private buildingEngine!: BABYLON.Engine;
  private buildingScene!: BABYLON.Scene;
  private screen: BABYLON.Mesh;
  private destroy$ = new Subject<void>();
  private nurse: BABYLON.Mesh;
  private waitingList: DocumentChange<Plan>[]; // Sửa kiểu khai báo
  userData: UserDocument = {
    gifters: [],
    bannerUrl: '',
    organize: '',
    watching: [],
    email: '',
    property: [],
    diamonds: 0, // Giá trị mặc định
    avata: '',
    menu: [],
    name: '',
  };
  private matchingTags: string[] = [];
  private productsSnapshot: any;
  private currentClient: any;
  private nurseTable: HTMLAudioElement;
  private pickCoin: HTMLAudioElement;
  private menuSound: HTMLAudioElement;
  private isMarketUIVisible: boolean = false; // Biến để theo dõi trạng thái hiển thị của marketUI
  private currentTip: GUI.InputTextArea = null;
  private currentBuyButton: GUI.Button = null;
  private currentCancelButton: GUI.Button = null;
  private buildingCamera: BABYLON.ArcRotateCamera;
  private hasJumpedOverCeiling = false; // Biến boolean để theo dõi trạng thái nhảy
  private bannerImage: GUI.Image;
  private bannerImageR: GUI.Image;
  private audioInstance: HTMLAudioElement | null = null;
  private userDocSnapshot: any;
  private table: any;
  private screenImage: GUI.Image;
  private avataImage: GUI.Image | undefined;
  private isUpdateInfoUIVisible: boolean = false;
  private isCommunityUIVisible: boolean = false;
  private isVisitedUserUIVisible: boolean = false;
  private visitedUserData: UserDocument;
  private inputHistory: GUI.InputTextArea;
  private lastUserActivityTime: number;
  private readonly INACTIVITY_THRESHOLD = 5000; // 5 seconds
  private readonly ROTATION_SPEED = 0.005;
  private resolvedList: DocumentChange<Plan>[];
  private clientPhoneInput: GUI.InputText;
  private isSearchEmaiUIVisible: boolean = false;
  private updateInfoUI: GUI.AdvancedDynamicTexture;
  private orderList: DocumentChange<Plan>[];
  peerConnection!: RTCPeerConnection;
  localStream!: MediaStream;
  authEmail: string;
  private callSubscription: any;
  confirmAccept: () => void;
  confirmReject: () => void;
  subscribeCallAnswer: any;
  callCandidateSubscription: any;
  providerPlanSubscription: any;
  clientPlanSubscription: any;
  private callDocId: string;
  localVideoFrame: HTMLVideoElement;
  remoteVideo: HTMLVideoElement;
  visitedUserId: string;
  private unsubscribeCandidates: () => void;

  constructor(
    private ngZone: NgZone,
    private firestore: Firestore,
    private storage: Storage,
    public notifier: NotifierService,
    public auth: Auth, // Đảm bảo bạn lấy dịch vụ Auth
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.canvas = this.renderCanvas.nativeElement;
    this.initGameScene();
    this.loadUserData();
    window.addEventListener('resize', this.onResize);
    document.addEventListener('fullscreenchange', this.onResize);
    document.addEventListener('webkitfullscreenchange', this.onResize);
    document.addEventListener('mozfullscreenchange', this.onResize);
    document.addEventListener('msfullscreenchange', this.onResize);
  }

  async loadUserData() {
    this.authService.getUserData().subscribe((userData) => {
      if (userData) {
        this.userDocSnapshot = userData;
        this.userData = userData;
        this.userData.diamonds = this.userData.diamonds || 0;
        this.authEmail = userData.email;

        // After loading user data, start listening for call data
        const callsQuery = query(
          collection(this.firestore, 'calls'),
          where('clientEmail', '==', this.authEmail),
        );

        // Use onSnapshot to subscribe to callsQuery and automatically update when changes occur
        onSnapshot(
          callsQuery,
          (changes) => {
            changes.forEach((change) => {
              const data = change.data() as CallData;
              const docId = change.id;
              if (data.status === 'incoming') {
                this.handleIncomingCall(docId, data);
              }
            });
          },
          (error) => {
            console.error('Error during Firestore operation:', error);
          },
        );
      } else {
        console.log('No user data available');
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('fullscreenchange', this.onResize);
    document.removeEventListener('webkitfullscreenchange', this.onResize);
    document.removeEventListener('mozfullscreenchange', this.onResize);
    document.removeEventListener('msfullscreenchange', this.onResize);
    this.gameEngine.dispose();
    if (this.buildingEngine) {
      this.buildingEngine.dispose();
    }

    this.destroy$.next();
    this.destroy$.complete();

    if (this.bannerImage && this.bannerImage.source) {
      URL.revokeObjectURL(this.bannerImage.source);
    }

    if (this.bannerImageR && this.bannerImageR.source) {
      URL.revokeObjectURL(this.bannerImageR.source);
    }
    if (this.screenImage && this.screenImage.source) {
      URL.revokeObjectURL(this.screenImage.source);
    }
    if (this.avataImage && this.avataImage.source) {
      URL.revokeObjectURL(this.avataImage.source);
    }
    if (this.providerPlanSubscription) {
      this.providerPlanSubscription.unsubscribe();
    }
    if (this.clientPlanSubscription) {
      this.clientPlanSubscription.unsubscribe();
    }
    if (this.providerPlanSubscription) {
      this.providerPlanSubscription.unsubscribe(); // Gọi phương thức unsubscribe nếu có
    }
  }

  private initGameScene(): void {
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';
    this.gameEngine = new BABYLON.Engine(this.canvas, true);
    this.gameScene = this.createGameScene();
    this.gameEngine.runRenderLoop(() => {
      this.gameScene.render();
    });

    this.recognition = new webkitSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.lang = 'vi-VI';

    this.recognition.onresult = (event: any) => {
      let finalTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript + ' ';
        }
      }
      this.transcriptText = finalTranscript;
      this.searchProblems();
    };

    this.recognition.onspeechend = () => {};
    this.preloadPickCoin();
  }

  private createGameScene(): BABYLON.Scene {
    const scene = new BABYLON.Scene(this.gameEngine);
    // Tạo skybox màu xanh blue
    const skyboxMaterial = new BABYLON.StandardMaterial(
      'skyboxMaterial',
      scene,
    );
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.diffuseColor = new BABYLON.Color3(0.2, 0.4, 0.8); // Màu xanh blue

    const skybox = BABYLON.MeshBuilder.CreateBox(
      'skybox',
      { size: 1000 },
      scene,
    );
    skybox.infiniteDistance = true;
    skybox.material = skyboxMaterial;

    const camera = new BABYLON.ArcRotateCamera(
      'camera',
      Math.PI / 2 - (30 * Math.PI) / 180,
      Math.PI / 2.5,
      10,
      BABYLON.Vector3.Zero(),
      scene,
    );
    camera.attachControl(this.canvas, true);
    camera.lowerBetaLimit = Math.PI / 2 - Math.PI / 10;
    camera.upperBetaLimit = Math.PI / 2;
    camera.lowerRadiusLimit = 8;
    camera.upperRadiusLimit = 12;
    camera.angularSensibilityX = 1000; // Adjust this value to change rotation speed
    camera.pinchDeltaPercentage = 0.005; // Adjust this value to change zoom speed

    const light = new BABYLON.HemisphericLight(
      'light',
      new BABYLON.Vector3(0, 1, 0),
      scene,
    );

    this.player = BABYLON.MeshBuilder.CreateSphere(
      'player',
      { diameter: 1 },
      scene,
    );
    this.player.position.set(0, 0.5, 4);
    this.player.actionManager = new BABYLON.ActionManager(scene);
    this.player.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, () => {
        this.ngZone.run(() => this.endGame());
      }),
    );

    this.start = BABYLON.MeshBuilder.CreateSphere(
      'start',
      { diameter: 0.6 },
      scene,
    );
    this.start.position.set(-2, 0.5, 5);
    this.start.actionManager = new BABYLON.ActionManager(scene);
    this.start.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, () => {
        this.ngZone.run(() => this.startRecognition());
      }),
    );

    this.stop = BABYLON.MeshBuilder.CreateSphere(
      'stop',
      { diameter: 0.5 },
      scene,
    );
    this.stop.position.set(2.5, 0.5, 6);
    this.stop.actionManager = new BABYLON.ActionManager(scene);
    this.stop.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, () => {
        this.ngZone.run(() => this.stopRecognition());
      }),
    );

    const bussinesPlace = BABYLON.MeshBuilder.CreateSphere(
      'bussinesPlace',
      { diameter: 0.5 },
      scene,
    );
    bussinesPlace.position.set(2.5, 0.5, 2);
    bussinesPlace.rotation.y = Math.PI;
    const homeIconMaterial = new BABYLON.StandardMaterial(
      'homeIconMaterial',
      scene,
    );
    homeIconMaterial.diffuseTexture = new BABYLON.Texture(
      'assets/textures/home-color-icon.png',
      scene,
    );
    bussinesPlace.material = homeIconMaterial;
    bussinesPlace.actionManager = new BABYLON.ActionManager(scene);
    bussinesPlace.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, () => {
        this.ngZone.run(() => this.changeScene());
      }),
    );

    const coinCreationInterval = setInterval(() => {
      if (!this.gameOver) {
        this.createCoin(scene);
      } else {
        clearInterval(coinCreationInterval);
      }
    }, 2000);

    return scene;
  }

  startRecognition() {
    this.recognition.start();
  }

  stopRecognition() {
    this.recognition.stop();
  }

  public async searchProblems() {
    const words = this.transcriptText.toLowerCase().split(' ');

    // Tạo truy vấn với 'array-contains-any'
    const problemsQuery = query(
      collection(this.firestore, 'problems'),
      where('tag', 'array-contains-any', words),
    );

    try {
      // Lấy tài liệu từ truy vấn
      const querySnapshot = await getDocs(problemsQuery);

      // Gán dữ liệu cho matchingProblems
      this.matchingProblems = querySnapshot.docs.map((doc) => {
        const data = doc.data() as Problem; // Đảm bảo kiểu dữ liệu đúng
        return {
          id: doc.id,
          ...data, // Kết hợp id với dữ liệu từ document
        };
      });
    } catch (error) {
      console.error('Error fetching problems:', error);
    }
  }

  private createCoin(scene: BABYLON.Scene): void {
    const availableProblems = this.matchingProblems.filter(
      (problem) => !this.pickedProblems.has(problem.name),
    );
    if (availableProblems.length > 0) {
      const randomProblem =
        availableProblems[Math.floor(Math.random() * availableProblems.length)];

      const option = Math.floor(Math.random() * 5) + 1;

      const coin = BABYLON.MeshBuilder.CreateSphere(
        'coin',
        { diameter: 2 },
        scene,
      );
      coin.position = new BABYLON.Vector3(Math.random() * 8 - 4, 0.25, -10);
      coin.metadata = {
        name: randomProblem.name,
        option,
        tag: randomProblem.tag,
      };

      const colors = {
        1: BABYLON.Color3.Green(),
        2: new BABYLON.Color3(0.5, 1, 0.5),
        3: BABYLON.Color3.Yellow(),
        4: BABYLON.Color3.FromHexString('#FFA500'),
        5: BABYLON.Color3.Red(),
      };

      const material = new BABYLON.StandardMaterial('coinMaterial', scene);
      material.diffuseColor = colors[option];
      coin.material = material;

      const advancedTexture =
        GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');
      const label = new GUI.TextBlock();
      label.text = randomProblem.name;
      label.color = 'white';
      label.fontSize = 24;
      advancedTexture.addControl(label);
      label.linkWithMesh(coin);

      // Update the label's fontSize based on camera-mesh distance
      scene.onBeforeRenderObservable.add(() => {
        // Calculate the distance between the camera and the mesh
        const distance = BABYLON.Vector3.Distance(
          scene.activeCamera.position,
          coin.position,
        );

        // Adjust the fontSize based on the distance
        const newFontSize = Math.max(12, 24 - distance * 0.5); // Adjust the scaling factor as needed
        label.fontSize = newFontSize;
      });

      coin.actionManager = new BABYLON.ActionManager(scene);
      coin.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          () => {
            this.ngZone.run(() => {
              this.pickCoin.currentTime = 0; // Đặt lại thời gian phát âm thanh
              this.pickCoin.play(); //
              this.plansProblem = this.plansProblem.filter(
                (plan) => plan.name !== randomProblem.name,
              );
              this.plansProblem.push({
                name: randomProblem.name,
                option,
                tag: randomProblem.tag,
              });
              this.pickedProblems.add(randomProblem.name);
              coin.dispose();
            });
          },
        ),
      );

      coin.onDispose = () => {
        advancedTexture.dispose();
      };

      this.coins.push(coin);

      scene.onBeforeRenderObservable.add(() => {
        coin.position.z += 0.1;
        if (coin.position.z > this.player.position.z + 1) {
          coin.dispose();
        }
      });

      // Tự hủy coin sau 8 giây
      setTimeout(() => {
        if (!coin.isDisposed()) {
          coin.dispose();
        }
      }, 8000);
    }
  }

  private endGame() {
    this.gameOver = true;
    this.coins.forEach((coin) => coin.dispose());
    this.coins = [];

    const gameOverDiv = document.getElementById('gameOver');
    if (gameOverDiv) {
      gameOverDiv.style.display = 'block';
    }
  }

  public replay() {
    const gameOverDiv = document.getElementById('gameOver');
    if (gameOverDiv) {
      gameOverDiv.style.display = 'none';
    }

    const guideContainer = document.getElementById('guideContainer');
    if (guideContainer) {
      guideContainer.style.display = 'none';
    }

    this.ngZone.runOutsideAngular(() => {
      this.gameOver = false;
      this.gameScene.dispose();
      this.gameScene = this.createGameScene();
      this.coins = [];
      this.plansProblem = [];
      this.pickedProblems.clear();
    });
    this.buttonFind = true;
    this.currentDocumentIndex = 0;
  }

  public async findDiamonds() {
    if (this.plansProblem.length > 0) {
      try {
        // Truy vấn plans dựa trên this.plansProblem
        const plansQuery = query(
          collection(this.firestore, 'plans'),
          where('plans', '==', this.plansProblem),
        );

        const planSnapshot = await getDocs(plansQuery); // Sử dụng getDocs để lấy kết quả

        if (!planSnapshot.empty) {
          // Lọc các tài liệu có guide.length > 2
          const filteredDocs = planSnapshot.docs.filter((doc) => {
            const plan = doc.data() as Plan;
            return plan.guide && plan.guide.length > 2;
          });

          this.loadGuides(filteredDocs); // Load guides from matched plans
          this.buttonFind = false;
          this.updateUserDiamonds((this.userData.diamonds += 1));
        } else {
          // Nếu snapshot rỗng, tìm guides của từng phần tử trong this.plansProblem
          this.guides = [];
          const promises = this.plansProblem.map(async (plan) => {
            const planQuery = query(
              collection(this.firestore, 'plans'),
              where('plans', 'array-contains', {
                name: plan.name,
                option: plan.option,
              }),
            );
            const planSnapshot = await getDocs(planQuery); // Sử dụng getDocs để lấy kết quả
            return planSnapshot.docs; // Trả về tài liệu
          });

          const planSnapshots = await Promise.all(promises);
          planSnapshots.forEach((planSnapshot) => {
            planSnapshot.forEach((doc) => {
              const plan = doc.data() as Plan;
              if (plan.guide && plan.guide.length > 0) {
                this.guides.push({
                  document: doc,
                  guides: plan.guide,
                  providerEmail: plan.providerEmail,
                } as DocumentGuide);
              }
            });
          });

          this.buttonFind = false; // Ẩn nút sau khi click
          this.displayGuide();
        }
      } catch (error) {
        console.error('Error getting plans documents: ', error);
      }
    }
  }

  private loadGuides(planDocs: QueryDocumentSnapshot<DocumentData>[]) {
    this.guides = [];
    planDocs.forEach((doc) => {
      const guideData = doc.data() as DocumentGuide;
      if (guideData && guideData.guides.length > 0) {
        this.guides.push({
          document: doc,
          guides: guideData.guides,
          providerEmail: guideData.providerEmail,
        });
      }
    });

    this.displayGuide();
  }

  private async displayGuide() {
    const guideContainer = document.getElementById('guideContainer');
    if (guideContainer) {
      guideContainer.style.display = 'block';
      guideContainer.innerHTML = '';
      const currentDocument = this.guides[this.currentDocumentIndex];
      const providerEmail = currentDocument.providerEmail; //lấy để lưu và savePlans

      const callButton = document.createElement('button');
      callButton.textContent = 'Call Doctor';
      callButton.onclick = async () => {
        window.location.href = 'tel:0981919115';
        this.savePlans(providerEmail);
      };
      guideContainer.appendChild(callButton);

      // Hiển thị các guides
      currentDocument.guides.forEach((guide) => {
        const guideElement = document.createElement('div');
        guideElement.textContent = guide;
        guideContainer.appendChild(guideElement);
      });

      const likeGuide = document.createElement('button');
      likeGuide.textContent = `Xin tư vấn`;
      likeGuide.style.cursor = 'pointer';
      likeGuide.addEventListener('click', () => this.savePlans(providerEmail));
      guideContainer.appendChild(likeGuide);
    }
  }

  private async savePlans(providerMail: string) {
    const historyArray: string[] = this.inputHistory?.text
      ? this.inputHistory.text.split('\n')
      : [];
    const planData: Plan = {
      clientEmail: this.userData.email,
      createdAt: new Date(),
      plans: this.plansProblem,
      providerEmail: providerMail,
      guide: null,
      history: historyArray,
      clientPhone: this.clientPhoneInput.text,
      fullName: '',
      medications: [],
    };

    try {
      // Lấy tham chiếu đến collection 'plans'
      const plansCollectionRef = collection(this.firestore, 'plans');
      // Thêm tài liệu vào collection
      const docRef = await addDoc(plansCollectionRef, planData);

      console.log('Plan saved with ID: ', docRef.id);
      this.notifier.notify(
        'success',
        `Đặt yêu cầu tới ${providerMail} thành công!`,
      );
    } catch (error) {
      console.error('Error adding plan: ', error);
    }
  }

  loadNextGuide() {
    if (this.currentDocumentIndex < this.guides.length - 1) {
      this.currentDocumentIndex++;
      this.displayGuide();
    }
  }

  private changeScene() {
    this.ngZone.runOutsideAngular(() => {
      this.gameEngine.dispose();
      this.gameScene.dispose();
      this.matchingProblems = [];
      this.coins = [];
      this.pickedProblems.clear();
      this.player.dispose();
      this.initBuildingScene();
    });
    const gameOverDiv = document.getElementById('gameOver');
    if (gameOverDiv) {
      gameOverDiv.style.display = 'none';
    }

    const guideContainer = document.getElementById('guideContainer');
    if (guideContainer) {
      guideContainer.style.display = 'none';
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initBuildingScene(): void {
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';
    this.buildingEngine = new BABYLON.Engine(this.canvas, true);
    this.buildingScene = this.createBuildingScene();
    this.buildingEngine.runRenderLoop(() => {
      this.buildingScene.render();
    });

    const plansQuery = query(
      collection(this.firestore, 'plans'),
      where('providerEmail', '==', this.userData.email),
    );

    this.providerPlanSubscription = onSnapshot(
      plansQuery,
      (plansSnapshot) => {
        // Lấy các thay đổi tài liệu
        const clientListSnapshot: DocumentChange<Plan>[] =
          plansSnapshot.docChanges() as DocumentChange<Plan>[];

        // Lọc danh sách chờ
        this.waitingList = clientListSnapshot.filter(
          (change) => change.doc.data().guide === null,
        );

        // Lọc danh sách đã giải quyết
        this.resolvedList = clientListSnapshot.filter((change) => {
          const data = change.doc.data();
          return data.guide !== null && data.clientEmail;
        });
      },
      (error) => {
        console.error('Error fetching plans:', error);
      },
    );

    const clientPlansQuery = query(
      collection(this.firestore, 'plans'),
      where('clientEmail', '==', this.userData.email),
    );

    this.clientPlanSubscription = onSnapshot(
      clientPlansQuery,
      (plansSnapshot) => {
        // Lấy các thay đổi tài liệu
        const orderListSnapshot: DocumentChange<Plan>[] =
          plansSnapshot.docChanges() as DocumentChange<Plan>[];

        // Lọc danh sách order dựa trên guide và clientEmail
        this.orderList = orderListSnapshot.filter((change) => {
          const data = change.doc.data();
          return data.guide !== null && data.clientEmail;
        });
      },
      (error) => {
        console.error('Error fetching client plans:', error);
      },
    );

    this.preloadSceneBuildingSound();
  }

  private createBuildingScene(): BABYLON.Scene {
    const scene = new BABYLON.Scene(this.buildingEngine);

    // Add gravity and enable physics
    const gravityVector = new BABYLON.Vector3(0, -9.81, 0);
    const physicsPlugin = new BABYLON.CannonJSPlugin(true, 10, CANNON);
    scene.enablePhysics(gravityVector, physicsPlugin);

    const ground = BABYLON.MeshBuilder.CreateGround(
      'ground',
      { width: 50, height: 50 },
      scene,
    );
    ground.physicsImpostor = new BABYLON.PhysicsImpostor(
      ground,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, restitution: 0.9 },
      scene,
    );

    const ceilingMaterial = new BABYLON.StandardMaterial(
      'ceilingMaterial',
      scene,
    );
    ceilingMaterial.diffuseColor = new BABYLON.Color3(1, 0.5, 1);

    const glassMaterial = new BABYLON.StandardMaterial('glassMaterial', scene);
    glassMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0.2);
    glassMaterial.alpha = 0.9;

    const playerMaterial = new BABYLON.StandardMaterial(
      'playerMaterial',
      scene,
    );
    playerMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0.2);

    const wallMaterial = new BABYLON.StandardMaterial('wallMaterial', scene);
    wallMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);

    const tableMaterial = new BABYLON.StandardMaterial('tableMaterial', scene);
    tableMaterial.diffuseColor = new BABYLON.Color3(0.6, 0.6, 0.6);

    this.nurse = BABYLON.MeshBuilder.CreateSphere(
      'nurse',
      { diameter: 1, segments: 16 },
      scene,
    );
    this.nurse.position.set(-5, 5, 5);
    const diffuseTexture = new BABYLON.Texture(
      'assets/textures/doctor.png',
      scene,
    );
    const material = new BABYLON.StandardMaterial('doctor', scene);
    material.diffuseTexture = diffuseTexture;
    this.nurse.material = material;
    this.nurse.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.nurse,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0.05, restitution: 0.9 },
      scene,
    );
    this.nurse.actionManager = new BABYLON.ActionManager(scene);
    this.nurse.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, () => {
        this.createMedGuideUI();
      }),
    );
    if (!this.nurse.rotationQuaternion) {
      this.nurse.rotationQuaternion = BABYLON.Quaternion.Identity();
    }

    this.table = BABYLON.MeshBuilder.CreateBox(
      'table',
      { height: 1, width: 1, depth: 1 },
      scene,
    );
    this.table.position.set(-20, 3, -1);
    this.table.material = tableMaterial;
    this.table.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.table,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0.1, restitution: 0.5 },
      scene,
    );
    this.table.physicsImpostor.registerOnPhysicsCollide(
      this.nurse.physicsImpostor,
      (_collider, _collidedAgainst) => {
        this.nurseTable.currentTime = 0;
        this.nurseTable.play();
      },
    );
    this.table.actionManager = new BABYLON.ActionManager(scene);
    this.table.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, () => {
        if (this.currentClient) {
          this.currentClient.dispose();
        }
        this.createClientBalls(scene);
      }),
    );
    const moveSpeed = 0.1;
    window.addEventListener('keydown', (event) => {
      if (event.key === 's') {
        this.table.position.z += moveSpeed;
      } else if (event.key === 'w') {
        this.table.position.z -= moveSpeed;
      } else if (event.key === 'd') {
        this.table.position.x -= moveSpeed;
      } else if (event.key === 'a') {
        this.table.position.x += moveSpeed;
      }
      if (this.table.intersectsMesh(ground, false)) {
        // Collision detected, handle accordingly
      }
    });
    let targetPosition = null;
    let isMoving = false;
    // Register onMouseMove event for capturing mouse position
    scene.onPointerMove = (evt) => {
      const pickResult = scene.pick(evt.clientX, evt.clientY);
      if (pickResult.hit) {
        targetPosition = pickResult.pickedPoint;
        isMoving = true;
      }
    };
    scene.registerBeforeRender(() => {
      if (isMoving && targetPosition) {
        const direction = targetPosition.subtract(this.nurse.position);
        if (direction.length() > 3) {
          // If the nurse is not yet at the target position
          direction.normalize();
          // Update nurse's rotation to face the target position
          const yaw = Math.atan2(direction.z, direction.x);
          const pitch = Math.atan2(
            direction.y,
            Math.sqrt(direction.x * direction.x + direction.z * direction.z),
          );
          this.nurse.rotationQuaternion =
            BABYLON.Quaternion.RotationYawPitchRoll(yaw, pitch, 0);
          // Move nurse towards target position
          const movementSpeed = 0.05; // Adjust speed factor as needed
          const moveDelta = direction.scale(movementSpeed);
          this.nurse.position.addInPlace(moveDelta);
          // Interpolate camera position smoothly
          const lerpFactor = 0.05; // Adjust this factor to increase/decrease camera lag
          const cameraPosition = BABYLON.Vector3.Lerp(
            this.buildingCamera.target,
            this.nurse.position,
            lerpFactor,
          );
          this.buildingCamera.setTarget(cameraPosition);
        } else {
          isMoving = false; // Stop moving if nurse is close enough to target
        }
      }
      // Kiểm tra vị trí y của nurse
      if (this.nurse.position.y > 8) {
        // Trên trần nhà
        if (!this.hasJumpedOverCeiling) {
          // Chưa nhảy lên trên trần nhà
          this.updateUserDiamonds((this.userData.diamonds += 1));
          this.hasJumpedOverCeiling = true; // Đánh dấu đã nhảy
        }
      } else {
        // Dưới trần nhà
        this.hasJumpedOverCeiling = false; // Đánh dấu chưa nhảy
      }
    });

    if (this.userData) {
      const pointLight = new BABYLON.PointLight(
        'pointLight',
        new BABYLON.Vector3(0, 8, 0),
        scene,
      );
      pointLight.diffuse = new BABYLON.Color3(1, 1, 1);
      pointLight.intensity = 0.5;
      pointLight.range = 35;
      scene.addLight(pointLight);

      const hemiLight = new BABYLON.HemisphericLight(
        'hemiLight',
        new BABYLON.Vector3(0, 6, 0),
        scene,
      );
      hemiLight.diffuse = new BABYLON.Color3(1, 0.5, 1);
      hemiLight.intensity = 0.6;
      scene.addLight(hemiLight);

      const ceiling = BABYLON.MeshBuilder.CreateBox(
        'ceiling',
        { width: 50, height: 0.5, depth: 50 },
        scene,
      );
      ceiling.position.set(0, 12, 0);
      ceiling.material = ceilingMaterial;
      ceiling.physicsImpostor = new BABYLON.PhysicsImpostor(
        ceiling,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      this.buildingCamera = new BABYLON.ArcRotateCamera(
        'buildingCamera',
        0,
        10,
        10,
        this.nurse.position,
        scene,
      );
      this.buildingCamera.attachControl(this.canvas, true);
      this.buildingCamera.lowerRadiusLimit = 2;
      this.buildingCamera.upperRadiusLimit = 12;
      this.buildingCamera.wheelDeltaPercentage = 0.01;
      this.buildingCamera.lowerBetaLimit = Math.PI / 2 - Math.PI / 15;
      this.buildingCamera.upperBetaLimit = Math.PI / 2 + Math.PI / 180;

      const skyboxMaterial = new BABYLON.StandardMaterial(
        'skyboxMaterial',
        scene,
      );
      skyboxMaterial.backFaceCulling = false;
      skyboxMaterial.diffuseColor = new BABYLON.Color3(0.2, 0.4, 0.8); // Màu xanh blue

      const skybox = BABYLON.MeshBuilder.CreateBox(
        'skybox',
        { size: 1000 },
        scene,
      );
      skybox.infiniteDistance = true;
      skybox.material = skyboxMaterial;

      BABYLON.SceneLoader.ImportMesh(
        '',
        '/assets/',
        'vending.glb',
        scene,
        (meshes) => {
          const diffuseTexture = new BABYLON.Texture(
            'assets/textures/Vending_Machine_VendingMachine_AlbedoTrans.png',
            scene,
          );
          Promise.all([
            new Promise((resolve) =>
              diffuseTexture.onLoadObservable.addOnce(resolve),
            ),
          ])
            .then(() => {
              const material = new BABYLON.StandardMaterial(
                'vendingMaterial',
                scene,
              );
              material.diffuseTexture = diffuseTexture;
              meshes.forEach((mesh) => {
                mesh.material = material;
                mesh.physicsImpostor = new BABYLON.PhysicsImpostor(
                  mesh,
                  BABYLON.PhysicsImpostor.BoxImpostor,
                  { mass: 0, restitution: 0.9 },
                  scene,
                );
                mesh.actionManager = new BABYLON.ActionManager(scene);
                mesh.actionManager.registerAction(
                  new BABYLON.ExecuteCodeAction(
                    BABYLON.ActionManager.OnPickTrigger,
                    async () => {
                      await this.createMarketUI(scene);
                    },
                  ),
                );
              });
              const vending = meshes[0];
              vending.position = new BABYLON.Vector3(24, 0, 3);
              vending.scaling = new BABYLON.Vector3(1, 1, 1);
              vending.rotate(BABYLON.Axis.X, -Math.PI / 2);
            })
            .catch((error) => {
              console.error('Texture failed to load:', error);
            });
        },
      );

      const wallF = BABYLON.MeshBuilder.CreatePlane(
        'wallF',
        { height: 12, width: 50 },
        scene,
      );
      wallF.position.set(0, 6, -25);
      wallF.rotation.y = Math.PI;
      wallF.material = wallMaterial;
      wallF.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallF,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const wallL = BABYLON.MeshBuilder.CreatePlane(
        'wallL',
        { height: 12, width: 50 },
        scene,
      );
      wallL.position.set(25, 6, 0);
      wallL.rotation.y = Math.PI / 2;
      wallL.material = wallMaterial;
      wallL.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallL,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const wallR = BABYLON.MeshBuilder.CreatePlane(
        'wallR',
        { height: 12, width: 50 },
        scene,
      );
      wallR.position.set(-25, 6, 0);
      wallR.rotation.y = -Math.PI / 2;
      wallR.material = wallMaterial;
      wallR.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallR,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const wallB = BABYLON.MeshBuilder.CreatePlane(
        'wallB',
        { height: 12, width: 50 },
        scene,
      );
      wallB.position.set(0, 6, 25);
      wallB.material = wallMaterial;
      wallB.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallB,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const screen = BABYLON.MeshBuilder.CreatePlane(
        'screen',
        { height: 8, width: 15 },
        scene,
      );
      this.screen = screen;
      screen.position.set(0, 5, -24.5);
      screen.rotation.y = Math.PI;
      screen.rotation.x = -Math.PI / 90;
      screen.material = glassMaterial;
      screen.actionManager = new BABYLON.ActionManager(scene);
      screen.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          async () => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/*';
            const files = await new Promise<FileList | null>((resolve) => {
              fileInput.addEventListener('change', (event) => {
                resolve((event.target as HTMLInputElement).files);
              });
              fileInput.click();
            });
            if (files && files.length > 0) {
              const file = files[0];
              const imageUrl = URL.createObjectURL(file);
              this.screenImage = new GUI.Image('screenImage', imageUrl);
              this.screenImage.stretch = GUI.Image.STRETCH_FILL;
              this.screenImage.width = 1;
              this.screenImage.height = 1;
              this.screenImage.top = '200px';
              const screenUI = GUI.AdvancedDynamicTexture.CreateForMesh(screen);
              screenUI.addControl(this.screenImage);
            }
          },
        ),
      );

      const community = BABYLON.MeshBuilder.CreatePlane(
        'community',
        { height: 5, width: 4 },
        scene,
      );
      community.position.set(-10, 3.5, -24.7);
      community.rotation.y = Math.PI;
      community.actionManager = new BABYLON.ActionManager(scene);
      community.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          () => {
            this.createCommunityUI();
          },
        ),
      );
      const communityTexture =
        GUI.AdvancedDynamicTexture.CreateForMesh(community);
      const scrollMenu = new GUI.ScrollViewer();
      scrollMenu.width = community.edgesWidth;
      scrollMenu.height = '1000px';
      scrollMenu.color = 'transparent';
      scrollMenu.background = 'rgba(50,0,0,0.2)';
      scrollMenu.isVisible = true;
      scrollMenu.top = '0px';
      scrollMenu.left = '0px';
      scrollMenu.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
      scrollMenu.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;

      const stackPanel = new GUI.StackPanel();
      stackPanel.width = scrollMenu.width;
      stackPanel.background = 'rgba(0,0,0,0.5)';
      scrollMenu.addControl(stackPanel);
      communityTexture.addControl(scrollMenu);

      const globeSearch = BABYLON.MeshBuilder.CreateSphere(
        'globeSearch',
        { diameter: 1, segments: 16 },
        scene,
      );
      globeSearch.position.set(20, 0.5, 15);
      globeSearch.material = glassMaterial;
      globeSearch.physicsImpostor = new BABYLON.PhysicsImpostor(
        globeSearch,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0.1, restitution: 0.5 },
        scene,
      );
      globeSearch.physicsImpostor.registerOnPhysicsCollide(
        this.nurse.physicsImpostor,
        (_collider, _collidedAgainst) => {
          this.nurseTable.currentTime = 0;
          this.nurseTable.play();
        },
      );
      globeSearch.actionManager = new BABYLON.ActionManager(scene);
      globeSearch.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          () => {
            this.searchEmailUI(scene);
          },
        ),
      );

      if (this.userData.watching && Array.isArray(this.userData.watching)) {
        for (const email of this.userData.watching) {
          const username = email.split('@')[0];
          const textBlock = new GUI.TextBlock();
          textBlock.text = username;
          textBlock.width = stackPanel.width;
          textBlock.height = '100px';
          textBlock.fontSize = 48;
          textBlock.color = 'white';
          stackPanel.addControl(textBlock);
        }
      }

      if (this.userData.property) {
        if (
          this.userData.property.some(
            (prop) => prop.propName === 'Bàn làm việc',
          )
        ) {
          BABYLON.SceneLoader.ImportMesh(
            '',
            '/assets/',
            'banL.glb',
            scene,
            (meshes) => {
              const diffuseTexture = new BABYLON.Texture(
                'assets/textures/RGB_5a0aa8925de14bf8b918f4221119f06f_Desk_A_diffuse.tga.png',
                scene,
              );
              Promise.all([
                new Promise((resolve) =>
                  diffuseTexture.onLoadObservable.addOnce(resolve),
                ),
              ])
                .then(() => {
                  const material = new BABYLON.StandardMaterial(
                    'banLMaterial',
                    scene,
                  );
                  material.diffuseTexture = diffuseTexture;
                  meshes.forEach((mesh) => {
                    mesh.material = material;
                    mesh.physicsImpostor = new BABYLON.PhysicsImpostor(
                      mesh,
                      BABYLON.PhysicsImpostor.BoxImpostor,
                      { mass: 0, restitution: 0.9 },
                      scene,
                    );
                    mesh.actionManager = new BABYLON.ActionManager(scene);
                    mesh.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPickTrigger,
                        async () => {
                          await this.resolvedPlanUI(scene);
                        },
                      ),
                    );
                  });
                  const banL = meshes[0];
                  banL.position = new BABYLON.Vector3(19, 0, -20);
                  banL.scaling = new BABYLON.Vector3(0.05, 0.05, 0.05);
                })
                .catch((error) => {
                  console.error('Texture failed to load:', error);
                });
            },
          );
        }
        if (this.userData.property.some((prop) => prop.propName === 'Giường')) {
          BABYLON.SceneLoader.ImportMesh(
            '',
            '/assets/',
            'bed.glb',
            scene,
            (meshes) => {
              meshes.forEach((mesh) => {
                mesh.material = wallMaterial;
                mesh.physicsImpostor = new BABYLON.PhysicsImpostor(
                  mesh,
                  BABYLON.PhysicsImpostor.BoxImpostor,
                  { mass: 0, restitution: 0.9 },
                  scene,
                );
                mesh.position = new BABYLON.Vector3(-15, 0, 21.6);
                mesh.scaling = new BABYLON.Vector3(0.18, 0.18, 0.18);
              });
            },
          );
        }
        if (
          this.userData.property.some(
            (prop) => prop.propName === 'Music Player',
          )
        ) {
          const musicPlayer = BABYLON.MeshBuilder.CreateBox(
            'musicPlayer',
            { width: 2, height: 1, depth: 0.6 },
            scene,
          );
          musicPlayer.position.set(-10, 1, -10);
          musicPlayer.rotation.z = Math.PI / 2;
          musicPlayer.material = playerMaterial;
          musicPlayer.physicsImpostor = new BABYLON.PhysicsImpostor(
            musicPlayer,
            BABYLON.PhysicsImpostor.BoxImpostor,
            { mass: 2, restitution: 0.9 },
            scene,
          );
          musicPlayer.actionManager = new BABYLON.ActionManager(scene);
          musicPlayer.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              () => {
                if (this.audioInstance) {
                  this.audioInstance.pause();
                }
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'audio/*';
                fileInput.click();

                fileInput.addEventListener('change', async (event: any) => {
                  const file = event.target.files[0];
                  if (file) {
                    const directoryPath = `music/${this.userData.email}/`;
                    const directoryRef = ref(this.storage, directoryPath);

                    try {
                      // Xóa các file cũ trong thư mục
                      const result = await listAll(directoryRef);
                      const deletePromises = result.items.map((item) =>
                        deleteObject(item),
                      );
                      await Promise.all(deletePromises);

                      // Tải file mới lên
                      const filePath = `${directoryPath}${file.name}`;
                      const fileRef = ref(this.storage, filePath);
                      const uploadTask = uploadBytesResumable(fileRef, file);

                      // Theo dõi quá trình tải file lên
                      uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                          // Có thể hiển thị tiến trình tải lên nếu cần
                          const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100;
                          console.log('Upload is ' + progress + '% done');
                        },
                        (error) => {
                          console.error('Error uploading file:', error);
                        },
                        async () => {
                          // Khi hoàn tất tải lên, lấy URL tải về
                          const fileURL = await getDownloadURL(fileRef);
                          if (this.audioInstance) {
                            this.audioInstance.pause();
                          }
                          this.audioInstance = new Audio(fileURL);
                          this.audioInstance.loop = true;
                          this.audioInstance.play();
                        },
                      );
                    } catch (error) {
                      console.error('Error handling music files: ', error);
                    }
                  }
                });
              },
            ),
          );
        }
        if (
          this.userData.property.some((prop) => prop.propName === 'Banner 1')
        ) {
          const banner = BABYLON.MeshBuilder.CreatePlane(
            'banner',
            { height: 6, width: 10 },
            scene,
          );
          banner.position.set(18, 5, -24.9);
          banner.rotation.y = Math.PI;
          banner.actionManager = new BABYLON.ActionManager(scene);
          banner.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              async () => {
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'image/*';
                fileInput.click();

                fileInput.addEventListener('change', async (event: any) => {
                  const file = event.target.files[0];
                  if (file) {
                    const directoryPath = `banner1/${this.userData.email}/`;

                    // Sử dụng ref(this.storage, directoryPath) để lấy tham chiếu đến thư mục
                    const directoryRef = ref(this.storage, directoryPath);

                    try {
                      // Lấy danh sách các file trong thư mục và xóa chúng
                      const result = await listAll(directoryRef);
                      const deletePromises = result.items.map((item) =>
                        deleteObject(item),
                      );
                      await Promise.all(deletePromises);

                      // Sau khi xóa các file cũ, tải file mới lên
                      const filePath = `${directoryPath}${file.name}`;
                      const fileRef = ref(this.storage, filePath);
                      const uploadTask = uploadBytesResumable(fileRef, file);

                      // Theo dõi tiến trình tải lên
                      uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                          // Hiển thị tiến trình nếu cần
                          const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100;
                          console.log('Upload is ' + progress + '% done');
                        },
                        (error) => {
                          console.error('Error uploading file:', error);
                        },
                        async () => {
                          // Khi tải lên hoàn tất, lấy URL của tệp
                          const fileURL = await getDownloadURL(fileRef);

                          // Hiển thị ảnh lên banner
                          if (this.bannerImage) {
                            bannerUI.removeControl(this.bannerImage);
                          }
                          this.bannerImage = new GUI.Image(
                            'bannerImage',
                            fileURL,
                          );
                          this.bannerImage.stretch = GUI.Image.STRETCH_FILL;
                          this.bannerImage.width = 1;
                          this.bannerImage.height = 1;
                          this.bannerImage.top = '200px';
                          bannerUI.addControl(this.bannerImage);
                        },
                      );
                    } catch (error) {
                      console.error('Error listing or deleting files: ', error);
                    }
                  }
                });
              },
            ),
          );

          const bannerUI = GUI.AdvancedDynamicTexture.CreateForMesh(banner);
          const directoryPath = `banner1/${this.userData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                // Lấy URL của file đầu tiên
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Hiển thị ảnh lên banner
                const bannerImage = new GUI.Image('bannerImage', fileURL);
                bannerImage.stretch = GUI.Image.STRETCH_FILL;
                bannerImage.width = 1;
                bannerImage.height = 1;
                bannerImage.top = '200px';
                bannerUI.addControl(bannerImage);
              }
            })
            .catch((error) => {
              console.error('Error getting file URLs: ', error);
            });
        }
        if (
          this.userData.property.some((prop) => prop.propName === 'Banner 2')
        ) {
          // Tạo bannerRight
          const bannerR = BABYLON.MeshBuilder.CreatePlane(
            'bannerR',
            { height: 6, width: 10 },
            scene,
          );
          bannerR.position.set(-18, 5, -24.9);
          bannerR.rotation.y = Math.PI;
          bannerR.actionManager = new BABYLON.ActionManager(scene);
          bannerR.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              () => {
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'image/*';
                fileInput.click();

                fileInput.addEventListener('change', (event: any) => {
                  const file = event.target.files[0];
                  if (file) {
                    const directoryPath = `banner2/${this.userData.email}/`;
                    const directoryRef = ref(this.storage, directoryPath);

                    // Lấy danh sách các file trong thư mục và xóa chúng
                    listAll(directoryRef)
                      .then(async (result) => {
                        const deletePromises = result.items.map((item) =>
                          deleteObject(item),
                        );
                        await Promise.all(deletePromises);

                        // Sau khi xóa các file cũ, tải file mới lên
                        const filePath = `${directoryPath}${file.name}`;
                        const fileRef = ref(this.storage, filePath);
                        const uploadTask = uploadBytesResumable(fileRef, file);

                        uploadTask
                          .then(() => {
                            // Sau khi upload thành công, lấy URL
                            return getDownloadURL(fileRef);
                          })
                          .then((fileURL) => {
                            // Kiểm tra kiểu dữ liệu của fileURL
                            if (typeof fileURL === 'string') {
                              // Hiển thị ảnh lên banner
                              if (this.bannerImageR) {
                                bannerUIR.removeControl(this.bannerImageR);
                              }
                              this.bannerImageR = new GUI.Image(
                                'bannerImageR',
                                fileURL,
                              );
                              this.bannerImageR.stretch =
                                GUI.Image.STRETCH_FILL;
                              this.bannerImageR.width = 1;
                              this.bannerImageR.height = 1;
                              this.bannerImageR.top = '200px';
                              bannerUIR.addControl(this.bannerImageR);
                            } else {
                              console.error(
                                'fileURL is not a string:',
                                fileURL,
                              );
                            }
                          })
                          .catch((error) => {
                            console.error(
                              'Error uploading file or getting URL: ',
                              error,
                            );
                          });
                      })
                      .catch((error) => {
                        console.error(
                          'Error listing or deleting files: ',
                          error,
                        );
                      });
                  }
                });
              },
            ),
          );

          const bannerUIR = GUI.AdvancedDynamicTexture.CreateForMesh(bannerR);
          const directoryPath = `banner2/${this.userData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          // Lấy danh sách các file trong thư mục
          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                // Lấy URL của file đầu tiên
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Kiểm tra kiểu dữ liệu của fileURL
                if (typeof fileURL === 'string') {
                  // Hiển thị ảnh lên banner
                  const bannerImageR = new GUI.Image('bannerImageR', fileURL);
                  bannerImageR.stretch = GUI.Image.STRETCH_FILL;
                  bannerImageR.width = 1;
                  bannerImageR.height = 1;
                  bannerImageR.top = '200px';
                  bannerUIR.addControl(bannerImageR);
                } else {
                  console.error('fileURL is not a string:', fileURL);
                }
              }
            })
            .catch((error) => {
              console.error('Error getting file URLs: ', error);
            });
        }

        if (this.userData.property.some((prop) => prop.propName === 'Avata')) {
          const avata = BABYLON.MeshBuilder.CreatePlane(
            'avata',
            { height: 4, width: 4 },
            scene,
          );
          avata.position.set(-24.9, 6, -5);
          avata.rotation.y = -Math.PI / 2;

          const avataUI = GUI.AdvancedDynamicTexture.CreateForMesh(avata);

          const uploadButton = GUI.Button.CreateSimpleButton(
            'uploadButton',
            'Upload Ảnh',
          );
          // Thêm sự kiện DOM trực tiếp cho nút
          uploadButton.onPointerDownObservable.add(() => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/*';
            fileInput.click();

            fileInput.addEventListener('change', async (event: any) => {
              const file = event.target.files[0];
              if (file) {
                const directoryPath = `avata/${this.userData.email}/`;
                const directoryRef = ref(this.storage, directoryPath);

                try {
                  // Lấy danh sách các file trong thư mục và xóa chúng
                  const result = await listAll(directoryRef);
                  const deletePromises = result.items.map((item) =>
                    deleteObject(item),
                  );
                  await Promise.all(deletePromises);

                  // Sau khi xóa các file cũ, tải file mới lên
                  const filePath = `${directoryPath}${file.name}`;
                  const fileRef = ref(this.storage, filePath);
                  const uploadTask = uploadBytesResumable(fileRef, file);

                  // Theo dõi quá trình upload
                  uploadTask
                    .then(async () => {
                      const fileURL = await getDownloadURL(fileRef);

                      // Cập nhật hình ảnh
                      if (this.avataImage) {
                        avataUI.removeControl(this.avataImage);
                      }
                      this.avataImage = new GUI.Image('avataImage', fileURL);
                      this.avataImage.stretch = GUI.Image.STRETCH_UNIFORM;
                      this.avataImage.width = 0.9;
                      this.avataImage.height = 0.9;
                      this.avataImage.top = 0;
                      this.avataImage.verticalAlignment =
                        GUI.Control.VERTICAL_ALIGNMENT_CENTER;
                      avataUI.addControl(this.avataImage);
                    })
                    .catch((error) => {
                      console.error(
                        'Error uploading file or getting URL: ',
                        error,
                      );
                    });
                } catch (error) {
                  console.error('Error listing or deleting files: ', error);
                }
              }
            });
          });
          avataUI.addControl(uploadButton);

          const nameShow = BABYLON.MeshBuilder.CreatePlane(
            'nameShow',
            { height: 3, width: 4 },
            scene,
          );
          nameShow.position.set(-24.9, 2.5, -5);
          nameShow.rotation.y = -Math.PI / 2;
          const nameShowUI = GUI.AdvancedDynamicTexture.CreateForMesh(nameShow);
          const userName = new GUI.TextBlock();
          userName.text = this.userData.name
            ? `${this.userData.name}`
            : 'No name';
          userName.color = 'yellow';
          userName.fontSize = 96;
          nameShowUI.addControl(userName);
        }
        if (this.userData.property.some((prop) => prop.propName === 'Menu')) {
          const organize = BABYLON.MeshBuilder.CreatePlane(
            'organize',
            { height: 3, width: 10 },
            scene,
          );
          organize.position.set(-24.9, 8, 3);
          organize.rotation.y = -Math.PI / 2;
          organize.actionManager = new BABYLON.ActionManager(scene);
          organize.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              async () => {
                await this.CreateUpdateInfoUI(scene);
              },
            ),
          );

          const organizeUI = GUI.AdvancedDynamicTexture.CreateForMesh(organize);
          const organ = new GUI.TextBlock();
          organ.text = this.userData.organize
            ? `${this.userData.organize}`
            : 'No organization';
          organ.color = 'green';
          organ.fontSize = 112;
          organizeUI.addControl(organ);

          const menu = BABYLON.MeshBuilder.CreatePlane(
            'menu',
            { height: 6, width: 10 },
            scene,
          );
          menu.position.set(-24.9, 3.5, 3);
          menu.rotation.y = -Math.PI / 2;

          const menuUI = GUI.AdvancedDynamicTexture.CreateForMesh(menu);

          const userMenu = new GUI.TextBlock();
          userMenu.text = this.userData.menu
            ? `\n${this.userData.menu.join('\n')}`
            : 'No menu';
          userMenu.color = 'green';
          userMenu.fontSize = 72;
          menuUI.addControl(userMenu);
        }
      }
    }
    return scene;
  }

  private searchEmailUI(_scene: BABYLON.Scene) {
    if (this.isSearchEmaiUIVisible) {
      console.log('Search Email UI is already visible.');
      return;
    }

    const searchEmaiUI =
      GUI.AdvancedDynamicTexture.CreateFullscreenUI('searchEmaiUI');

    const emailSearchInput = new GUI.InputText();
    emailSearchInput.width = '250px';
    emailSearchInput.height = '45px';
    emailSearchInput.color = 'white';
    emailSearchInput.background = 'black';
    emailSearchInput.placeholderText = 'Search email...';
    emailSearchInput.top = '-150px';
    emailSearchInput.left = '300px';
    emailSearchInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    emailSearchInput.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    searchEmaiUI.addControl(emailSearchInput);

    const searchButton = GUI.Button.CreateSimpleButton(
      'searchButton',
      'Search',
    );
    searchButton.width = '150px';
    searchButton.height = '45px';
    searchButton.color = 'white';
    searchButton.background = 'black';
    searchButton.top = '-100px';
    searchButton.left = '300px';
    searchButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    searchButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    searchEmaiUI.addControl(searchButton);

    const searchResultText = new GUI.TextBlock();
    searchResultText.width = '400px';
    searchResultText.height = '60px';
    searchResultText.color = 'white';
    searchResultText.fontSize = 14;
    searchResultText.top = '-200px';
    searchResultText.left = '0px';
    searchResultText.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    searchResultText.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    searchEmaiUI.addControl(searchResultText);

    searchButton.onPointerDownObservable.add(async () => {
      const searchEmail = emailSearchInput.text.toLowerCase();
      const userQuery = query(
        collection(this.firestore, 'User'),
        where('email', '>=', searchEmail),
        where('email', '<', searchEmail + '\uf8ff'),
      );

      try {
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
          // Xóa các kết quả tìm kiếm cũ nếu có
          searchResultText.text = 'Found users:';
          querySnapshot.forEach((doc) => {
            const userDoc = doc.data();
            // Tách phần trước dấu '@' của email
            const emailPrefix = userDoc.email.split('@')[0];
            // Tạo nút cho mỗi email tìm được
            const emailButton = GUI.Button.CreateSimpleButton(
              'emailButton_' + userDoc.email,
              emailPrefix,
            );
            emailButton.width = '250px';
            emailButton.height = '40px';
            emailButton.color = 'white';
            emailButton.background = 'blue';
            emailButton.top = `${
              -120 + 40 * querySnapshot.docs.indexOf(doc)
            }px`; // Sắp xếp các nút theo chiều dọc
            emailButton.verticalAlignment =
              GUI.Control.VERTICAL_ALIGNMENT_CENTER;
            emailButton.horizontalAlignment =
              GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
            emailButton.onPointerUpObservable.add(() => {
              this.initVisitedScene(userDoc.email);
              this.isSearchEmaiUIVisible = false;
              searchEmaiUI.dispose();
            });
            searchEmaiUI.addControl(emailButton);
          });
        } else {
          searchResultText.text = 'No user found with that email';
        }
      } catch (error) {
        searchResultText.text = 'Error searching for user';
        console.error('Error searching for user:', error);
      }
    });

    const closeButton = GUI.Button.CreateSimpleButton('closeButton', 'Đóng');
    closeButton.width = '200px';
    closeButton.height = '50px';
    closeButton.top = '110px';
    closeButton.left = '300';
    closeButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    closeButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    closeButton.onPointerDownObservable.addOnce(() => {
      searchEmaiUI.dispose();
      this.isSearchEmaiUIVisible = false;
    });
    searchEmaiUI.addControl(closeButton);
    this.isSearchEmaiUIVisible = true;
  }

  private resolvedPlanUI(_scene: BABYLON.Scene) {
    const resolvedPlanUI =
      GUI.AdvancedDynamicTexture.CreateFullscreenUI('resolvedPlanUI');

    const resolvedPlanList = new GUI.ScrollViewer();
    resolvedPlanList.width = '250px';
    resolvedPlanList.height = '400px';
    resolvedPlanList.color = 'transparent';
    resolvedPlanList.background = 'rgba(50,0,0,0.2)';
    resolvedPlanList.top = '-50px';
    resolvedPlanList.left = '-300px';
    resolvedPlanList.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    resolvedPlanList.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    const resolvedPlanStackPanel = new GUI.StackPanel();
    resolvedPlanStackPanel.width = resolvedPlanList.width;
    resolvedPlanList.addControl(resolvedPlanStackPanel);
    resolvedPlanUI.addControl(resolvedPlanList);
    resolvedPlanStackPanel.clearControls();

    const list: Plan[] = this.resolvedList
      .map((doc) => doc.doc.data() as Plan) // Sử dụng doc.doc.data() thay vì doc.payload.doc.data()
      .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

    list.forEach((item) => {
      const username = item.clientEmail.split('@')[0];
      const button = GUI.Button.CreateSimpleButton(
        `button_${item.clientEmail}`,
        username,
      );
      button.width = '100%';
      button.height = '50px';
      button.color = 'white';
      button.background = 'black';

      button.onPointerClickObservable.add(() => {
        this.menuSound.currentTime = 0;
        this.menuSound.play();
        const planInfo = new GUI.InputTextArea();
        planInfo.width = '400px';
        planInfo.height = '250px';
        planInfo.text = `${item.history ? item.history.join('\n') : ''}
          \nHướng dẫn: ${item.guide ? item.guide.join('\n') : ''}
          \nThời gian: ${
            item.createdAt
              ? new Date(item.createdAt.seconds * 1000).toLocaleString()
              : ''
          }
          \nĐiện thoại: ${item.clientPhone ? item.clientPhone : ''}`;
        planInfo.isReadOnly = true;
        planInfo.color = 'white';
        planInfo.background = 'rgba(50,0,0,0.7)';
        planInfo.top = '-100px';
        planInfo.left = '100px';
        planInfo.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
        planInfo.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;

        resolvedPlanUI.addControl(planInfo);

        setTimeout(() => resolvedPlanUI.removeControl(planInfo), 5000);
      });

      resolvedPlanStackPanel.addControl(button);
    });

    const closeButton = GUI.Button.CreateSimpleButton('closeButton', 'Đóng');
    closeButton.width = '200px';
    closeButton.height = '50px';
    closeButton.top = '110px';
    closeButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    closeButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    closeButton.onPointerDownObservable.addOnce(() => {
      resolvedPlanUI.dispose();
    });
    resolvedPlanUI.addControl(closeButton);
  }

  private createCommunityUI() {
    if (this.isCommunityUIVisible) {
      console.log('Community UI is already visible.');
      return;
    }
    const communityUI =
      GUI.AdvancedDynamicTexture.CreateFullscreenUI('communityUI');

    if (this.userData.gifters) {
      this.userData.gifters = this.userData.gifters.map((gifter) => ({
        ...gifter,
        lastSent: gifter.lastSent || '1970-01-01T00:00:00Z', // Default date
        seen: gifter.seen !== undefined ? gifter.seen : true, // Default to true or false based on your requirement
      }));
      const giftersList = new GUI.ScrollViewer();
      giftersList.width = '250px';
      giftersList.height = '400px';
      giftersList.color = 'transparent';
      giftersList.background = 'rgba(50,0,0,0.2)';
      giftersList.top = '-50px';
      giftersList.left = '-300px';
      giftersList.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      giftersList.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      const giftersStackPanel = new GUI.StackPanel();
      giftersStackPanel.width = giftersList.width;
      giftersList.addControl(giftersStackPanel);
      communityUI.addControl(giftersList);
      giftersStackPanel.clearControls();
      this.updateGifterList(giftersStackPanel);
    }

    if (this.userData.watching) {
      const watchingList = new GUI.ScrollViewer();
      watchingList.width = '250px';
      watchingList.height = '400px';
      watchingList.color = 'transparent';
      watchingList.background = 'rgba(50,0,0,0.2)';
      watchingList.top = '-20px';
      watchingList.left = '300px';
      watchingList.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      watchingList.horizontalAlignment =
        GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      const watchingStackPanel = new GUI.StackPanel();
      watchingStackPanel.width = watchingList.width;
      watchingList.addControl(watchingStackPanel);
      communityUI.addControl(watchingList);
      this.updateUserList(this.userData.watching, watchingStackPanel);
      // Add filter input
      const filterInput = new GUI.InputText();
      filterInput.width = '250px';
      filterInput.height = '30px';
      filterInput.color = 'white';
      filterInput.background = 'black';
      filterInput.placeholderText = 'Filter users...';
      filterInput.top = '-250px';
      filterInput.left = '300px';
      filterInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      filterInput.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      communityUI.addControl(filterInput);

      filterInput.onTextChangedObservable.add(() => {
        const filterText = filterInput.text.toLowerCase();
        const filteredUsers = this.userData.watching.filter((email) =>
          email.toLowerCase().includes(filterText),
        );
        this.updateUserList(filteredUsers, watchingStackPanel);
      });
    }

    const closeButton = GUI.Button.CreateSimpleButton('closeButton', 'Đóng');
    closeButton.width = '200px';
    closeButton.height = '50px';
    closeButton.top = '110px';
    closeButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    closeButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    closeButton.onPointerDownObservable.addOnce(() => {
      this.isCommunityUIVisible = false;
      communityUI.dispose();
    });
    communityUI.addControl(closeButton);

    this.isCommunityUIVisible = true;
  }

  private updateGifterList(giftersStackPanel: GUI.StackPanel) {
    giftersStackPanel.clearControls();
    // Sort gifters by lastSent in descending order
    this.userData.gifters.sort(
      (a, b) => new Date(b.lastSent).getTime() - new Date(a.lastSent).getTime(),
    );
    this.userData.gifters.forEach((gifter) => {
      const username = gifter.email.split('@')[0];
      const mark = gifter.seen ? '' : '🔴';
      const button = GUI.Button.CreateSimpleButton(
        'button+' + username,
        username + mark,
      );
      button.width = giftersStackPanel.highlightLineWidth;
      button.height = '50px';
      button.color = 'white';
      button.background = 'green';
      button.onPointerClickObservable.addOnce(() => {
        this.initVisitedScene(gifter.email);
        this.isCommunityUIVisible = false;
        if (!gifter.seen) {
          this.updateGifterSeenStatus(gifter.email);
        }
      });
      giftersStackPanel.addControl(button);
    });
  }
  private updateGifterSeenStatus = async (gifterEmail: string) => {
    const gifterIndex = this.userData.gifters?.findIndex(
      (gifter) => gifter.email === gifterEmail,
    );

    if (
      gifterIndex !== undefined &&
      gifterIndex !== -1 &&
      this.userData.gifters
    ) {
      this.userData.gifters[gifterIndex].seen = true;

      // Simplified update using this.userDocSnapshot
      this.userDocSnapshot.ref.update({ gifters: this.userData.gifters });
    }
  };

  private updateUserList = (users: string[], stackPanel: GUI.StackPanel) => {
    stackPanel.clearControls();
    users.forEach((email) => {
      const username = email.split('@')[0];
      const button = GUI.Button.CreateSimpleButton(
        'button' + username,
        username,
      );
      button.width = stackPanel.highlightLineWidth;
      button.height = '50px';
      button.color = 'white';
      button.background = 'green';
      button.onPointerClickObservable.addOnce(() => {
        this.initVisitedScene(email);
        this.isCommunityUIVisible = false;
        this.menuSound.currentTime = 0;
        this.menuSound.play();
      });
      stackPanel.addControl(button);
    });
  };

  async CreateUpdateInfoUI(_scene: BABYLON.Scene) {
    // Kiểm tra xem marketUI có đang hiển thị không
    if (this.isUpdateInfoUIVisible) {
      console.log('Market UI is already visible.');
      return;
    }
    const menu = this.userData.menu || [];
    const name = this.userData.name || '';
    const organize = this.userData.organize || '';

    const menuList = menu.map((g) => `${g}\n`).join('');
    const updateInfoUI =
      GUI.AdvancedDynamicTexture.CreateFullscreenUI('updateInfoUI');
    this.updateInfoUI = updateInfoUI;
    const menuInput = new GUI.InputTextArea();
    menuInput.width = '400px';
    menuInput.height = '250px';
    menuInput.color = 'white';
    menuInput.background = 'rgba(50,0,0,0.2)';
    menuInput.isVisible = true;
    menuInput.top = '-100px';
    menuInput.left = '300px';
    menuInput.text = `\n${menuList}`;
    menuInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    menuInput.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    updateInfoUI.addControl(menuInput);

    const nameInput = new GUI.InputText();
    nameInput.width = '400px';
    nameInput.height = '50px';
    nameInput.text = name;
    nameInput.color = 'white';
    nameInput.background = 'rgba(50,0,0,0.2)';
    nameInput.top = '-200px';
    nameInput.left = '-300px';
    nameInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    nameInput.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    updateInfoUI.addControl(nameInput);

    const organizeInput = new GUI.InputText();
    organizeInput.width = '400px';
    organizeInput.height = '50px';
    organizeInput.text = organize;
    organizeInput.color = 'white';
    organizeInput.background = 'rgba(50,0,0,0.2)';
    organizeInput.top = '-100px';
    organizeInput.left = '-300px';
    organizeInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    organizeInput.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    updateInfoUI.addControl(organizeInput);

    const updateButton = GUI.Button.CreateSimpleButton(
      'updateButton',
      'Cập nhật',
    );
    updateButton.width = '200px';
    updateButton.height = '50px';
    updateButton.top = '60px';
    updateButton.left = '200px';
    updateButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    updateButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    updateInfoUI.addControl(updateButton);
    updateButton.onPointerClickObservable.addOnce(async () => {
      if (!this.userData.menu) {
        this.userData.menu = [];
      }
      if (!this.userData.name) {
        this.userData.name = '';
      }
      if (!this.userData.organize) {
        this.userData.organize = '';
      }
      // Cập nhật các trường cụ thể trong userData
      const menuArray: string[] = menuInput.text.split('\n');
      const updatedData = {
        name: nameInput.text,
        organize: organizeInput.text,
        menu: menuArray,
      };

      // Cập nhật các trường trong Firestore mà không ảnh hưởng đến các trường khác
      await this.userDocSnapshot.ref.update(updatedData);

      // Cập nhật userData để phản ánh thay đổi
      this.userData.name = nameInput.text;
      this.userData.organize = organizeInput.text;
      this.userData.menu = menuArray;
      this.isUpdateInfoUIVisible = false;
      updateInfoUI.dispose();
      this.initBuildingScene();
    });

    const shareButton = GUI.Button.CreateSimpleButton('shareButton', 'Chia sẻ');
    shareButton.width = '400px';
    shareButton.height = '50px';
    shareButton.top = '60px';
    shareButton.left = '-300px';
    shareButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    shareButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    updateInfoUI.addControl(shareButton);

    shareButton.onPointerClickObservable.addOnce(() => {
      const url = `https://mevata.space`;
      const title = `Check out ${this.userData.name}'s profile!`;
      this.shareOnSocialMedia(url, title);
    });

    const closeButton = GUI.Button.CreateSimpleButton('closeButton', 'Đóng');
    closeButton.width = '200px';
    closeButton.height = '50px';
    closeButton.top = '60px';
    closeButton.left = '400px';
    closeButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    closeButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    closeButton.onPointerDownObservable.addOnce(() => {
      this.isUpdateInfoUIVisible = false;
      updateInfoUI.dispose();
    });
    updateInfoUI.addControl(closeButton);

    this.isUpdateInfoUIVisible = true;
  }

  private shareOnSocialMedia(url: string, title: string) {
    const socialPlatforms = [
      {
        name: 'Facebook',
        url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url,
        )}`,
      },
      {
        name: 'Twitter',
        url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          url,
        )}&text=${encodeURIComponent(title)}`,
      },
      {
        name: 'LinkedIn',
        url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          url,
        )}&title=${encodeURIComponent(title)}`,
      },
    ];

    socialPlatforms.forEach((platform, index) => {
      const button = GUI.Button.CreateSimpleButton(
        `share_${platform.name}`,
        `Share on ${platform.name}`,
      );
      button.width = '200px';
      button.height = '40px';
      button.color = 'white';
      button.background = 'blue';
      button.top = `${120 + index * 50}px`;
      button.left = '-200px';
      button.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      button.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      button.onPointerClickObservable.add(() => {
        window.open(platform.url, '_blank');
      });
      this.updateInfoUI.addControl(button);
    });
  }

  private async initVisitedScene(email: string) {
    try {
      if (email) {
        this.ngZone.runOutsideAngular(() => {
          this.buildingEngine.dispose();
          this.buildingScene.dispose();
        });

        // Tạo truy vấn để tìm người dùng theo email
        const visitedUserQuery = query(
          collection(this.firestore, 'User'),
          where('email', '==', email),
        );

        // Lấy dữ liệu người dùng từ Firestore
        const visitedUserSnapshot = await getDocs(visitedUserQuery);

        if (!visitedUserSnapshot.empty) {
          const visitedUserDocSnapshot = visitedUserSnapshot.docs[0];
          this.visitedUserData = visitedUserDocSnapshot.data() as UserDocument; // Ép kiểu dữ liệu
          this.visitedUserId = visitedUserDocSnapshot.id;

          const visitedEngine = new BABYLON.Engine(this.canvas, true);
          const scene = this.createVisitedScene(visitedEngine);

          if (scene) {
            // Bắt đầu vòng lặp render
            visitedEngine.runRenderLoop(() => {
              scene.render();
            });

            // Xử lý sự kiện thay đổi kích thước cửa sổ
            window.addEventListener('resize', () => {
              visitedEngine.resize();
            });
          } else {
            console.error('Scene creation failed');
          }
        } else {
          console.error('No user document found');
        }
      }
    } catch (error) {
      console.error('Error during Firestore operation:', error);
    }
  }

  private createVisitedScene(engine: BABYLON.Engine): BABYLON.Scene | null {
    const scene = new BABYLON.Scene(engine);
    const ceilingMaterial = new BABYLON.StandardMaterial(
      'ceilingMaterial',
      scene,
    );
    ceilingMaterial.diffuseColor = new BABYLON.Color3(1, 0.5, 1);

    const glassMaterial = new BABYLON.StandardMaterial('glassMaterial', scene);
    glassMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0.2);
    glassMaterial.alpha = 0.9;

    const playerMaterial = new BABYLON.StandardMaterial(
      'playerMaterial',
      scene,
    );
    playerMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0.2);

    const wallMaterial = new BABYLON.StandardMaterial('wallMaterial', scene);
    wallMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);

    if (this.visitedUserData) {
      const pointLight = new BABYLON.PointLight(
        'pointLight',
        new BABYLON.Vector3(0, 8, 0),
        scene,
      );
      pointLight.diffuse = new BABYLON.Color3(1, 1, 1);
      pointLight.intensity = 0.5;
      pointLight.range = 35;
      scene.addLight(pointLight);

      const hemiLight = new BABYLON.HemisphericLight(
        'hemiLight',
        new BABYLON.Vector3(0, 6, 0),
        scene,
      );
      hemiLight.diffuse = new BABYLON.Color3(1, 0.5, 1);
      hemiLight.intensity = 0.6;
      scene.addLight(hemiLight);

      const visitedCamera = new BABYLON.ArcRotateCamera(
        'visitedCamera',
        0,
        Math.PI / 2,
        10,
        new BABYLON.Vector3(0, 5, 0),
        scene,
      );
      visitedCamera.attachControl(this.canvas, true);
      visitedCamera.lowerRadiusLimit = 10;
      visitedCamera.upperRadiusLimit = 45;
      visitedCamera.lowerBetaLimit = Math.PI / 2 - Math.PI / 15;
      visitedCamera.upperBetaLimit = Math.PI / 2 + Math.PI / 180;
      this.lastUserActivityTime = Date.now();

      visitedCamera.attachControl(this.canvas, true);
      this.canvas.addEventListener('pointermove', () => {
        this.lastUserActivityTime = Date.now();
      });

      scene.registerBeforeRender(() => {
        const currentTime = Date.now();
        if (
          currentTime - this.lastUserActivityTime >
          this.INACTIVITY_THRESHOLD
        ) {
          visitedCamera.alpha += this.ROTATION_SPEED;
        }
      });

      const ground = BABYLON.MeshBuilder.CreateGround(
        'ground',
        { width: 50, height: 50 },
        scene,
      );
      ground.physicsImpostor = new BABYLON.PhysicsImpostor(
        ground,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const ceiling = BABYLON.MeshBuilder.CreateBox(
        'ceiling',
        { width: 50, height: 0.5, depth: 50 },
        scene,
      );
      ceiling.position.set(0, 12, 0);
      ceiling.material = ceilingMaterial;

      const wallF = BABYLON.MeshBuilder.CreatePlane(
        'wallF',
        { height: 12, width: 50 },
        scene,
      );
      wallF.position.set(0, 6, -25);
      wallF.rotation.y = Math.PI;
      wallF.material = wallMaterial;
      wallF.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallF,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const wallL = BABYLON.MeshBuilder.CreatePlane(
        'wallL',
        { height: 12, width: 50 },
        scene,
      );
      wallL.position.set(25, 6, 0);
      wallL.rotation.y = Math.PI / 2;
      wallL.material = wallMaterial;
      wallL.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallL,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const wallR = BABYLON.MeshBuilder.CreatePlane(
        'wallR',
        { height: 12, width: 50 },
        scene,
      );
      wallR.position.set(-25, 6, 0);
      wallR.rotation.y = -Math.PI / 2;
      wallR.material = wallMaterial;
      wallR.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallR,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      const wallB = BABYLON.MeshBuilder.CreatePlane(
        'wallB',
        { height: 12, width: 50 },
        scene,
      );
      wallB.position.set(0, 6, 25);
      wallB.material = wallMaterial;
      wallB.physicsImpostor = new BABYLON.PhysicsImpostor(
        wallB,
        BABYLON.PhysicsImpostor.BoxImpostor,
        { mass: 0, restitution: 0.9 },
        scene,
      );

      BABYLON.SceneLoader.ImportMesh(
        '',
        '/assets/',
        'vending.glb',
        scene,
        (meshes) => {
          const diffuseTexture = new BABYLON.Texture(
            'assets/textures/Vending_Machine_VendingMachine_AlbedoTrans.png',
            scene,
          );
          Promise.all([
            new Promise((resolve) =>
              diffuseTexture.onLoadObservable.addOnce(resolve),
            ),
          ])
            .then(() => {
              const material = new BABYLON.StandardMaterial(
                'vendingMaterial',
                scene,
              );
              material.diffuseTexture = diffuseTexture;
              meshes.forEach((mesh) => {
                mesh.material = material;
              });
              const vending = meshes[0];
              vending.position = new BABYLON.Vector3(24, 0, 3);
              vending.scaling = new BABYLON.Vector3(1, 1, 1);
              vending.rotate(BABYLON.Axis.X, -Math.PI / 2);
            })
            .catch((error) => {
              console.error('Texture failed to load:', error);
            });
        },
      );

      const screen = BABYLON.MeshBuilder.CreatePlane(
        'screen',
        { height: 8, width: 15 },
        scene,
      );
      screen.position.set(0, 5, -24.5);
      screen.rotation.y = Math.PI;
      screen.rotation.x = -Math.PI / 90;
      screen.material = glassMaterial;

      const buttonBackPlane = BABYLON.MeshBuilder.CreatePlane(
        'buttonPlane',
        { height: 2, width: 7 },
        scene,
      );
      buttonBackPlane.position.set(-21, 10.5, -24.9); // Điều chỉnh vị trí cao hơn screen
      buttonBackPlane.rotation.y = Math.PI;
      buttonBackPlane.rotation.x = -Math.PI / 90;
      const buttonBackUI =
        GUI.AdvancedDynamicTexture.CreateForMesh(buttonBackPlane);
      const backButton = GUI.Button.CreateSimpleButton(
        'backButton',
        'Trở về >>',
      );
      backButton.color = 'white';
      backButton.cornerRadius = 20;
      backButton.background = 'blue';
      backButton.fontSize = 120;
      buttonBackUI.addControl(backButton);
      backButton.onPointerUpObservable.add(() => {
        this.ngZone.runOutsideAngular(() => {
          if (this.audioInstance) {
            this.audioInstance.pause();
          }
          engine.dispose();
          scene.dispose();
          this.initBuildingScene();
        });
      });

      if (this.visitedUserData.property) {
        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Bàn làm việc',
          )
        ) {
          BABYLON.SceneLoader.ImportMesh(
            '',
            '/assets/',
            'banL.glb',
            scene,
            (meshes) => {
              const diffuseTexture = new BABYLON.Texture(
                'assets/textures/RGB_5a0aa8925de14bf8b918f4221119f06f_Desk_A_diffuse.tga.png',
                scene,
              );
              Promise.all([
                new Promise((resolve) =>
                  diffuseTexture.onLoadObservable.addOnce(resolve),
                ),
              ])
                .then(() => {
                  const material = new BABYLON.StandardMaterial(
                    'banLMaterial',
                    scene,
                  );
                  material.diffuseTexture = diffuseTexture;
                  meshes.forEach((mesh) => {
                    mesh.material = material;
                  });
                  const banL = meshes[0];
                  banL.position = new BABYLON.Vector3(19, 0, -20);
                  banL.scaling = new BABYLON.Vector3(0.05, 0.05, 0.05);
                })
                .catch((error) => {
                  console.error('Texture failed to load:', error);
                });
            },
          );
        }
        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Giường',
          )
        ) {
          BABYLON.SceneLoader.ImportMesh(
            '',
            '/assets/',
            'bed.glb',
            scene,
            (meshes) => {
              meshes.forEach((mesh) => {
                mesh.material = wallMaterial;
                mesh.position = new BABYLON.Vector3(-15, 0, 21.6);
                mesh.scaling = new BABYLON.Vector3(0.18, 0.18, 0.18);
              });
            },
          );
        }
        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Music Player',
          )
        ) {
          const musicPlayer = BABYLON.MeshBuilder.CreateBox(
            'musicPlayer',
            { width: 2, height: 1, depth: 0.6 },
            scene,
          );
          musicPlayer.position.set(-10, 1, -10);
          musicPlayer.material = playerMaterial;
          musicPlayer.rotation.z = Math.PI / 2;

          const directoryPath = `music/${this.visitedUserData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          // Lấy danh sách các file trong thư mục
          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Kiểm tra kiểu dữ liệu của fileURL
                if (typeof fileURL === 'string') {
                  if (this.audioInstance) {
                    this.audioInstance.pause();
                  }
                  this.audioInstance = new Audio(fileURL);
                  this.audioInstance.loop = true;

                  musicPlayer.actionManager = new BABYLON.ActionManager(scene);
                  musicPlayer.actionManager.registerAction(
                    new BABYLON.ExecuteCodeAction(
                      BABYLON.ActionManager.OnPickTrigger,
                      () => {
                        if (this.audioInstance.paused) {
                          this.audioInstance.play();
                        } else {
                          this.audioInstance.pause();
                        }
                      },
                    ),
                  );
                } else {
                  console.error('fileURL is not a string:', fileURL);
                }
              }
            })
            .catch((error) => {
              console.error('Error fetching music files: ', error);
            });
        }

        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Banner 1',
          )
        ) {
          const banner = BABYLON.MeshBuilder.CreatePlane(
            'banner',
            { height: 6, width: 10 },
            scene,
          );
          banner.position.set(18, 5, -24.9);
          banner.rotation.y = Math.PI;
          const bannerUI = GUI.AdvancedDynamicTexture.CreateForMesh(banner);

          const directoryPath = `banner1/${this.visitedUserData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          // Lấy danh sách các file trong thư mục
          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Kiểm tra kiểu dữ liệu của fileURL
                if (typeof fileURL === 'string') {
                  const bannerImage = new GUI.Image('bannerImage', fileURL);
                  bannerImage.stretch = GUI.Image.STRETCH_FILL;
                  bannerImage.width = 1;
                  bannerImage.height = 1;
                  bannerImage.top = '200px';
                  bannerUI.addControl(bannerImage);

                  // Thêm sự kiện click để phóng to
                  banner.actionManager = new BABYLON.ActionManager(scene);
                  banner.actionManager.registerAction(
                    new BABYLON.ExecuteCodeAction(
                      BABYLON.ActionManager.OnPickTrigger,
                      () => {
                        const zoomedImage = new GUI.Image(
                          'zoomedImage',
                          fileURL,
                        );
                        zoomedImage.stretch = GUI.Image.STRETCH_UNIFORM;
                        zoomedImage.width = 0.8;
                        zoomedImage.height = 0.8;

                        const fullscreenUI =
                          GUI.AdvancedDynamicTexture.CreateFullscreenUI(
                            'FullscreenUI',
                          );
                        fullscreenUI.addControl(zoomedImage);

                        const closeButton = GUI.Button.CreateSimpleButton(
                          'closeButton',
                          'X',
                        );
                        closeButton.width = '60px';
                        closeButton.height = '40px';
                        closeButton.color = 'white';
                        closeButton.background = 'red';
                        closeButton.top = '10px';
                        closeButton.left = '10px';
                        closeButton.onPointerUpObservable.add(() => {
                          fullscreenUI.dispose();
                        });
                        fullscreenUI.addControl(closeButton);
                      },
                    ),
                  );
                } else {
                  console.error('fileURL is not a string:', fileURL);
                }
              }
            })
            .catch((error) => {
              console.error('Error fetching banner files: ', error);
            });
        }

        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Banner 2',
          )
        ) {
          const bannerR = BABYLON.MeshBuilder.CreatePlane(
            'bannerR',
            { height: 6, width: 10 },
            scene,
          );
          bannerR.position.set(-18, 5, -24.9);
          bannerR.rotation.y = Math.PI;
          const bannerUIR = GUI.AdvancedDynamicTexture.CreateForMesh(bannerR);

          const directoryPath = `banner2/${this.visitedUserData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          // Lấy danh sách các file trong thư mục
          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Kiểm tra kiểu dữ liệu của fileURL
                if (typeof fileURL === 'string') {
                  const bannerImageR = new GUI.Image('bannerImageR', fileURL);
                  bannerImageR.stretch = GUI.Image.STRETCH_FILL;
                  bannerImageR.width = 1;
                  bannerImageR.height = 1;
                  bannerImageR.top = '200px';
                  bannerUIR.addControl(bannerImageR);

                  // Thêm sự kiện click để phóng to
                  bannerR.actionManager = new BABYLON.ActionManager(scene);
                  bannerR.actionManager.registerAction(
                    new BABYLON.ExecuteCodeAction(
                      BABYLON.ActionManager.OnPickTrigger,
                      () => {
                        const zoomedImage = new GUI.Image(
                          'zoomedImageR',
                          fileURL,
                        );
                        zoomedImage.stretch = GUI.Image.STRETCH_UNIFORM;
                        zoomedImage.width = 0.8;
                        zoomedImage.height = 0.8;

                        const fullscreenUI =
                          GUI.AdvancedDynamicTexture.CreateFullscreenUI(
                            'FullscreenUIR',
                          );
                        fullscreenUI.addControl(zoomedImage);

                        const closeButton = GUI.Button.CreateSimpleButton(
                          'closeButtonR',
                          'X',
                        );
                        closeButton.width = '60px';
                        closeButton.height = '40px';
                        closeButton.color = 'white';
                        closeButton.background = 'red';
                        closeButton.top = '10px';
                        closeButton.left = '10px';
                        closeButton.onPointerUpObservable.add(() => {
                          fullscreenUI.dispose();
                        });
                        fullscreenUI.addControl(closeButton);
                      },
                    ),
                  );
                } else {
                  console.error('fileURL is not a string:', fileURL);
                }
              }
            })
            .catch((error) => {
              console.error('Error fetching banner files: ', error);
            });
        }

        if (
          this.visitedUserData.property.some(
            (prop) => prop.propName === 'Avata',
          )
        ) {
          const avata = BABYLON.MeshBuilder.CreatePlane(
            'avata',
            { height: 4, width: 4 },
            scene,
          );
          avata.position.set(-24.9, 6, -5);
          avata.rotation.y = -Math.PI / 2;
          const avataUI = GUI.AdvancedDynamicTexture.CreateForMesh(avata);

          const directoryPath = `avata/${this.visitedUserData.email}/`;
          const directoryRef = ref(this.storage, directoryPath);

          // Lấy danh sách các file trong thư mục
          listAll(directoryRef)
            .then(async (result) => {
              if (result.items.length > 0) {
                // Lấy URL của file đầu tiên
                const fileRef = result.items[0];
                const fileURL = await getDownloadURL(fileRef);

                // Kiểm tra kiểu dữ liệu của fileURL
                if (typeof fileURL === 'string') {
                  // Hiển thị ảnh lên banner
                  const avataImage = new GUI.Image('avataImage', fileURL);
                  avataImage.stretch = GUI.Image.STRETCH_UNIFORM;
                  avataImage.width = 0.9;
                  avataImage.height = 0.9;
                  avataImage.top = 0;
                  avataImage.verticalAlignment =
                    GUI.Control.VERTICAL_ALIGNMENT_CENTER;
                  avataUI.addControl(avataImage);
                } else {
                  console.error('fileURL is not a string:', fileURL);
                }
              }
            })
            .catch((error) => {
              console.error('Error fetching avatar files: ', error);
            });

          const nameShow = BABYLON.MeshBuilder.CreatePlane(
            'nameShow',
            { height: 3, width: 4 },
            scene,
          );
          nameShow.position.set(-24.9, 2.5, -5);
          nameShow.rotation.y = -Math.PI / 2;
          const nameShowUI = GUI.AdvancedDynamicTexture.CreateForMesh(nameShow);
          const userName = new GUI.TextBlock();
          userName.text = this.visitedUserData.name
            ? `${this.visitedUserData.name}`
            : 'No name';
          userName.color = 'yellow';
          userName.fontSize = 96;
          nameShowUI.addControl(userName);
        }

        if (
          this.visitedUserData.property.some((prop) => prop.propName === 'Menu')
        ) {
          const organize = BABYLON.MeshBuilder.CreatePlane(
            'organize',
            { height: 3, width: 10 },
            scene,
          );
          organize.position.set(-24.9, 8, 3);
          organize.rotation.y = -Math.PI / 2;
          const organizeUI = GUI.AdvancedDynamicTexture.CreateForMesh(organize);
          const organ = new GUI.TextBlock();
          organ.text = this.visitedUserData.organize
            ? `${this.visitedUserData.organize}`
            : 'No organization';
          organ.color = 'green';
          organ.fontSize = 112;
          organizeUI.addControl(organ);

          const menu = BABYLON.MeshBuilder.CreatePlane(
            'menu',
            { height: 6, width: 10 },
            scene,
          );
          menu.position.set(-24.9, 3.5, 3);
          menu.rotation.y = -Math.PI / 2;
          menu.actionManager = new BABYLON.ActionManager(scene);
          menu.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              () => {
                this.createVisitedUserUI();
              },
            ),
          );
          const menuTexture = GUI.AdvancedDynamicTexture.CreateForMesh(menu);
          const userMenu = new GUI.TextBlock();
          userMenu.text = this.visitedUserData.menu
            ? `\n${this.visitedUserData.menu.join('\n')}`
            : 'No menu';
          userMenu.color = 'green';
          userMenu.fontSize = 72;
          menuTexture.addControl(userMenu);

          const organize1 = BABYLON.MeshBuilder.CreatePlane(
            'organize1',
            { height: 3, width: 10 },
            scene,
          );
          organize1.position.set(0, 8, -24.95);

          const organizeUI1 =
            GUI.AdvancedDynamicTexture.CreateForMesh(organize1);
          const organ1 = new GUI.TextBlock();
          organ1.text = this.visitedUserData.organize
            ? `${this.visitedUserData.organize}`
            : 'No organization';
          organ1.color = 'green';
          organ1.fontSize = 112;
          organizeUI1.addControl(organ1);

          const menu1 = BABYLON.MeshBuilder.CreatePlane(
            'menu1',
            { height: 6, width: 10 },
            scene,
          );
          menu1.position.set(0, 3.5, -24.95);

          const menuTexture1 = GUI.AdvancedDynamicTexture.CreateForMesh(menu1);
          const userMenu1 = new GUI.TextBlock();
          userMenu1.text = this.visitedUserData.menu
            ? `\n${this.visitedUserData.menu.join('\n')}`
            : 'No menu';
          userMenu1.color = 'green';
          userMenu1.fontSize = 72;
          menuTexture1.addControl(userMenu1);
        }
      }
    }
    return scene;
  }

  private createVisitedUserUI() {
    if (this.isVisitedUserUIVisible) {
      console.log('Visited User UI is already visible.');
      return;
    }
    const menu = this.visitedUserData.menu || [];
    const name = this.visitedUserData.name || '';
    const organize = this.visitedUserData.organize || '';

    const visitedUserUI =
      GUI.AdvancedDynamicTexture.CreateFullscreenUI('visitedUserUI');

    const visitedUserOrganize = new GUI.TextBlock();
    visitedUserOrganize.text = organize;
    visitedUserOrganize.width = '400px';
    visitedUserOrganize.height = '50px';
    visitedUserOrganize.color = 'yellow';
    visitedUserOrganize.top = '-200px';
    visitedUserOrganize.left = '-250px';
    visitedUserOrganize.verticalAlignment =
      GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    visitedUserOrganize.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    visitedUserUI.addControl(visitedUserOrganize);

    const visitedUserMenu = new GUI.TextBlock();
    visitedUserMenu.text = `\n${menu.join('\n')}`;
    visitedUserMenu.width = '400px';
    visitedUserMenu.height = '250px';
    visitedUserMenu.color = 'green';
    visitedUserMenu.top = '-50px';
    visitedUserMenu.left = '-250px';
    visitedUserMenu.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    visitedUserMenu.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    visitedUserMenu.textHorizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    visitedUserMenu.paddingLeft = '10px';
    visitedUserUI.addControl(visitedUserMenu);

    const inputHistory = new GUI.InputTextArea();
    this.inputHistory = inputHistory;
    inputHistory.width = '400px';
    inputHistory.height = '150px';
    inputHistory.text = 'Thông tin đặt dịch vụ:';
    inputHistory.isVisible = true;
    inputHistory.color = 'white';
    inputHistory.background = 'rgba(50,0,0,0.2)';
    inputHistory.top = '100px';
    inputHistory.left = '-250px';
    inputHistory.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    inputHistory.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    visitedUserUI.addControl(inputHistory);

    const visitedUserName = new GUI.TextBlock();
    visitedUserName.text = name;
    visitedUserName.width = '400px';
    visitedUserName.height = '50px';
    visitedUserName.color = 'white';
    visitedUserName.top = '-200px';
    visitedUserName.left = '180px';
    visitedUserName.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    visitedUserName.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    visitedUserUI.addControl(visitedUserName);

    const addWatchingButton = GUI.Button.CreateSimpleButton(
      'addWatchingButton',
      'Theo dõi / Bỏ theo dõi',
    );
    addWatchingButton.width = '250px';
    addWatchingButton.height = '50px';
    addWatchingButton.top = '-140px';
    addWatchingButton.left = '250px';
    addWatchingButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    addWatchingButton.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    addWatchingButton.onPointerDownObservable.addOnce(() => {
      this.addWatching();
    });
    visitedUserUI.addControl(addWatchingButton);

    const sendGiftButton = GUI.Button.CreateSimpleButton(
      'sendGiftButton',
      'Tặng điểm',
    );
    sendGiftButton.width = '250px';
    sendGiftButton.height = '50px';
    sendGiftButton.top = '-80px';
    sendGiftButton.left = '250px';
    sendGiftButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    sendGiftButton.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    sendGiftButton.onPointerDownObservable.add(() => {
      this.sendDiamond(this.visitedUserData.email);
    });
    visitedUserUI.addControl(sendGiftButton);

    const clientPhoneInput = new GUI.InputText();
    this.clientPhoneInput = clientPhoneInput;
    clientPhoneInput.width = '250px';
    clientPhoneInput.height = '30px';
    clientPhoneInput.color = 'white';
    clientPhoneInput.background = 'black';
    clientPhoneInput.placeholderText = 'Điện thoại liên hệ...';
    clientPhoneInput.top = '-30px';
    clientPhoneInput.left = '250px';
    clientPhoneInput.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    clientPhoneInput.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    visitedUserUI.addControl(clientPhoneInput);

    const sendOrderButton = GUI.Button.CreateSimpleButton(
      'sendOrderButton',
      'Đặt dịch vụ',
    );
    sendOrderButton.width = '250px';
    sendOrderButton.height = '50px';
    sendOrderButton.top = '15px';
    sendOrderButton.left = '250px';
    sendOrderButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    sendOrderButton.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    sendOrderButton.onPointerDownObservable.addOnce(() => {
      this.isVisitedUserUIVisible = false;
      this.savePlans(this.visitedUserData.email);
    });
    visitedUserUI.addControl(sendOrderButton);

    const videCallButton = GUI.Button.CreateSimpleButton(
      'videCallButton',
      'Gọi Video',
    );
    videCallButton.width = '250px';
    videCallButton.height = '50px';
    videCallButton.top = '75px';
    videCallButton.left = '250px';
    videCallButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    videCallButton.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    videCallButton.onPointerDownObservable.add(() => {
      this.isVisitedUserUIVisible = false;
      this.initiateVideoCall(this.userData.email, this.visitedUserData.email);
    });
    visitedUserUI.addControl(videCallButton);

    const closeButton = GUI.Button.CreateSimpleButton('closeButton', 'Đóng');
    closeButton.width = '250px';
    closeButton.height = '50px';
    closeButton.top = '135px';
    closeButton.left = '250px';
    closeButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    closeButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    closeButton.onPointerDownObservable.addOnce(() => {
      this.isVisitedUserUIVisible = false;
      visitedUserUI.dispose();
    });
    visitedUserUI.addControl(closeButton);

    this.isVisitedUserUIVisible = true;
  }

  private addWatching() {
    if (!this.userData.watching) {
      this.userData.watching = [];
    }
    if (!this.userData.watching.includes(this.visitedUserData.email)) {
      this.userData.watching.push(this.visitedUserData.email);
      this.userDocSnapshot.ref.update({
        watching: this.userData.watching,
      });
    } else {
      console.log('In watching already!');
      // Loại bỏ visitedUserData.email khỏi this.userData.watching
      const index = this.userData.watching.indexOf(this.visitedUserData.email);
      this.userData.watching.splice(index, 1);
      this.userDocSnapshot.ref
        .update({
          watching: this.userData.watching,
        })
        .then(() => {
          console.log('Đã loại bỏ khỏi danh sách theo dõi');
        })
        .catch((error) => {
          console.error('Lỗi khi cập nhật danh sách theo dõi:', error);
        });
    }
  }

  private displayPlansOnScreen(
    plans: { name: string; option: number }[],
    history?: string[],
  ) {
    const dynamicTexture = new BABYLON.DynamicTexture(
      'DynamicTexture',
      512,
      this.buildingScene,
      true,
    );
    dynamicTexture.hasAlpha = false;
    const material = new BABYLON.StandardMaterial(
      'DynamicTextureMaterial',
      this.buildingScene,
    );
    material.diffuseTexture = dynamicTexture;
    this.screen.material = material;

    const textureSize = dynamicTexture.getSize();
    const textHeight = 32; // Chiều cao của font
    const lineSpacing = 1.5; // Khoảng cách giữa các dòng

    // Calculate total lines (plans + history)
    const totalLines = plans.length + (history ? history.length + 1 : 0); // +1 for "History:" header

    let startY =
      (textureSize.height - totalLines * textHeight * lineSpacing) / 2; // Vị trí bắt đầu vẽ text theo chiều dọc

    // Draw plans
    for (const plan of plans) {
      const planText = `${plan.name}: ${plan.option}`;
      dynamicTexture.drawText(
        planText,
        null,
        startY,
        'bold 32px Arial',
        'white',
        'transparent',
        true,
      );
      startY += textHeight * lineSpacing; // Tăng vị trí bắt đầu cho dòng tiếp theo
    }

    // Draw history if available
    if (history && history.length > 0) {
      startY += textHeight * lineSpacing; // Add extra space before history
      dynamicTexture.drawText(
        'History:',
        null,
        startY,
        'bold 32px Arial',
        'yellow',
        'transparent',
        true,
      );
      startY += textHeight * lineSpacing;

      for (const historyItem of history) {
        dynamicTexture.drawText(
          historyItem,
          null,
          startY,
          '28px Arial',
          'lightblue',
          'transparent',
          true,
        );
        startY += textHeight * lineSpacing;
      }
    }
  }

  private createMedGuideUI() {
    const userPlanSnapshot: Plan[] = this.orderList
      .map((doc) => doc.doc.data() as Plan) // Sử dụng doc.doc.data() thay vì doc.payload.doc.data()
      .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

    if (userPlanSnapshot.length > 0) {
      const userPlanData = userPlanSnapshot[0];

      const userPlanProviderEmail = userPlanData.providerEmail;
      const medications = userPlanData.medications || [];
      const guide = userPlanData.guide || [];

      const guideList = guide.map((g) => `${g}\n`).join('');
      const fullScreenUI =
        GUI.AdvancedDynamicTexture.CreateFullscreenUI('FullScreenUI');

      if (medications.length > 0) {
        const medicationsList = medications
          .map((med) => `${med.brandName} - ${med.quantity} - ${med.use}\n`)
          .join('');
        const medicationsBlock = new GUI.TextBlock();
        medicationsBlock.text = `Đơn thuốc:\n${medicationsList}`;
        medicationsBlock.width = '400px';
        medicationsBlock.color = 'black';
        medicationsBlock.top = '-180px';
        medicationsBlock.left = '-300px';
        medicationsBlock.verticalAlignment =
          GUI.Control.VERTICAL_ALIGNMENT_CENTER;
        medicationsBlock.horizontalAlignment =
          GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
        fullScreenUI.addControl(medicationsBlock);
      }

      const guideBlock = new GUI.TextBlock();
      guideBlock.text = `Hướng dẫn:\n${guideList}`;
      guideBlock.width = '400px';
      guideBlock.color = 'black';
      guideBlock.top = '-180px';
      guideBlock.left = '100px';
      guideBlock.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      guideBlock.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      fullScreenUI.addControl(guideBlock);

      const callButton = GUI.Button.CreateSimpleButton(
        'callButton',
        'Gọi điện',
      );
      callButton.width = '200px';
      callButton.height = '50px';
      callButton.top = '-140px';
      callButton.left = '450px';
      callButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      callButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      fullScreenUI.addControl(callButton);
      callButton.onPointerClickObservable.addOnce(() => {
        window.location.href = 'tel:0981919115';
      });

      const awardButton = GUI.Button.CreateSimpleButton(
        'awardButton',
        'Tặng điểm',
      );
      awardButton.width = '200px';
      awardButton.height = '50px';
      awardButton.top = '-70px';
      awardButton.left = '450px';
      awardButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      awardButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      fullScreenUI.addControl(awardButton);
      awardButton.onPointerClickObservable.add(() => {
        this.sendDiamond(userPlanProviderEmail);
        this.menuSound.currentTime = 0; // Đặt lại thời gian phát âm thanh
        this.menuSound.play(); // Phát âm thanh ngay lập tức
      });

      const closeButton = GUI.Button.CreateSimpleButton('closeButton', 'Đóng');
      closeButton.width = '200px';
      closeButton.height = '50px';
      closeButton.top = '0px';
      closeButton.left = '450px';
      closeButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      closeButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      closeButton.onPointerDownObservable.addOnce(() => {
        fullScreenUI.dispose();
      });
      fullScreenUI.addControl(closeButton);
    }
  }

  private async sendDiamond(email: string) {
    try {
      const receiverQuery = query(
        collection(this.firestore, 'User'),
        where('email', '==', email),
      );

      const receiverSnapshot = await getDocs(receiverQuery);

      if (!receiverSnapshot.empty && this.userData.diamonds >= 1) {
        const doc = receiverSnapshot.docs[0];
        const receiverData = doc.data() as UserDocument;
        let receiverDiamonds = receiverData.diamonds || 0;
        receiverDiamonds += 1;

        // Kiểm tra xem người dùng đã có trong mảng gifters chưa
        const existingGifterIndex =
          receiverData.gifters?.findIndex(
            (gifter) => gifter.email === this.userData.email,
          ) ?? -1;
        const updateData: Partial<UserDocument> = {
          diamonds: receiverDiamonds,
        };

        if (existingGifterIndex === -1) {
          const newGifter = {
            email: this.userData.email,
            lastSent: new Date().toISOString(),
            seen: false,
          };
          updateData.gifters = receiverData.gifters
            ? [...receiverData.gifters, newGifter]
            : [newGifter];
        } else if (receiverData.gifters) {
          // Cập nhật thời gian lastSent và trạng thái seen nếu người dùng đã là gifter
          receiverData.gifters[existingGifterIndex] = {
            ...receiverData.gifters[existingGifterIndex],
            lastSent: new Date().toISOString(),
            seen: false,
          };
          updateData.gifters = receiverData.gifters;
        }

        // Cập nhật dữ liệu người nhận
        const docRef = doc.ref as DocumentReference<UserDocument>; // Ép kiểu cho đúng
        await updateDoc(docRef, updateData); // Sử dụng updateDoc để cập nhật tài liệu
        this.updateUserDiamonds((this.userData.diamonds -= 1));
      }
    } catch (error) {
      console.error('Error sending diamond:', error);
    }
  }

  private createClientBalls(scene: BABYLON.Scene) {
    if (this.waitingList.length > 0) {
      // Lấy tài liệu đầu tiên từ waitingList
      const clientDoc = this.waitingList[0]; // Giả định waitingList chứa DocumentChange<Plan>
      const clientData = clientDoc.doc.data() as Plan; // Truy cập data() qua doc
      const clientId = clientDoc.doc.id; // Truy cập id() qua doc

      BABYLON.SceneLoader.ImportMesh(
        '',
        '/assets/',
        'patient.glb',
        scene,
        (meshes) => {
          const diffuseTexture = new BABYLON.Texture(
            'assets/textures/patient.png',
            scene,
          );
          Promise.all([
            new Promise((resolve) =>
              diffuseTexture.onLoadObservable.addOnce(resolve),
            ),
          ])
            .then(() => {
              const material = new BABYLON.StandardMaterial(
                'patientMaterial',
                scene,
              );
              material.diffuseTexture = diffuseTexture;
              meshes.forEach((mesh) => {
                mesh.material = material;
              });
              const patient = meshes[0];
              patient.scaling = new BABYLON.Vector3(0.01, 0.01, 0.01);
              patient.position = this.table.position;
              patient.rotate(BABYLON.Axis.Y, Math.PI / 2);
              patient.physicsImpostor = new BABYLON.PhysicsImpostor(
                patient,
                BABYLON.PhysicsImpostor.BoxImpostor,
                { mass: 0, restitution: 0.9 },
                scene,
              );
              patient.physicsImpostor.registerOnPhysicsCollide(
                this.nurse.physicsImpostor,
                (_collider, _collidedAgainst) => {
                  this.pickCoin.currentTime = 0;
                  this.pickCoin.play();
                  patient.dispose();
                  this.displayPlansOnScreen(
                    clientData.plans,
                    clientData.history,
                  );
                  this.updatePlanGuideUI(
                    clientId,
                    clientData.plans,
                    clientData.providerEmail,
                    clientData.clientEmail,
                  );
                },
              );
              this.currentClient = patient;

              const targetX = Math.random() * 10 - 5;
              const targetZ = Math.random() * 10 - 5;
              const targetPosition = new BABYLON.Vector3(targetX, 0, targetZ);
              const distance = BABYLON.Vector3.Distance(
                patient.position,
                targetPosition,
              );
              const speed = 0.01;
              const frames = distance / speed;
              scene.registerBeforeRender(() => {
                patient.position = BABYLON.Vector3.Lerp(
                  patient.position,
                  targetPosition,
                  speed,
                );
                if (
                  BABYLON.Vector3.Distance(patient.position, targetPosition) <
                  0.01
                ) {
                  const newTargetX = Math.random() * 10 - 5;
                  const newTargetZ = Math.random() * 10 - 5;
                  targetPosition.set(newTargetX, 0, newTargetZ);
                }
              });
            })
            .catch((error) => {
              console.error('Texture failed to load:', error);
            });
        },
      );
    }
  }

  private updatePlanGuideUI(
    clientId: string,
    clientPlans: { name: string; option: number; tag: string[] }[],
    providerEmail: string,
    clientEmail: string,
  ) {
    const inputGuideForClient =
      GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');

    const scrollMenu = new GUI.ScrollViewer();
    scrollMenu.width = '250px';
    scrollMenu.height = '400px';
    scrollMenu.color = 'transparent';
    scrollMenu.background = 'rgba(50,0,0,0.2)';
    scrollMenu.top = '-100px';
    scrollMenu.left = '300px';
    scrollMenu.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    scrollMenu.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;

    try {
      if (
        this.userData.property &&
        Array.isArray(this.userData.property) &&
        this.userData.property.length > 0
      ) {
        const stackPanel = new GUI.StackPanel();
        scrollMenu.addControl(stackPanel);
        this.userData.property
          .filter((prop) => prop.classify && prop.classify.includes('y tế'))
          .forEach((prop) => {
            const button = GUI.Button.CreateSimpleButton(
              'button' + prop.propName,
              prop.propName,
            );
            button.width = '250px';
            button.height = '50px';
            button.color = 'white';
            button.background = 'black';
            button.onPointerClickObservable.addOnce(() => {
              this.menuSound.currentTime = 0; // Đặt lại thời gian phát âm thanh
              this.menuSound.play(); // Phát âm thanh ngay lập tức
              this.matchingTags = [...this.matchingTags, ...prop.propTag]; // Using spread operator
            });
            stackPanel.addControl(button);
          });
      }
    } catch (error) {
      console.error('Error creating scroll menu:', error);
    }

    const inputGuide = new GUI.InputTextArea();
    inputGuide.width = '400px';
    inputGuide.height = '280px';
    inputGuide.text = '';
    inputGuide.color = 'white';
    inputGuide.background = 'rgba(50,0,0,0.2)';
    inputGuide.top = '-150px';
    inputGuide.left = '-300px';
    inputGuide.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    inputGuide.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;

    const saveGuideButton = GUI.Button.CreateSimpleButton(
      'saveGuideButton',
      'Lưu hướng dẫn',
    );
    saveGuideButton.width = '200px';
    saveGuideButton.height = '50px';
    saveGuideButton.top = '20px';
    saveGuideButton.left = '-200px';
    saveGuideButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    saveGuideButton.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    saveGuideButton.onPointerDownObservable.addOnce(async () => {
      inputGuideForClient.dispose();
      if (inputGuide.text.trim() == '') {
        this.notifier.notify('warning', `Không có hướng dẫn được cập nhật!`);
        return;
      }
      const guideArray: string[] = inputGuide.text.split('\n');

      // Tạo docRef cho tài liệu cần cập nhật
      const docRef = doc(this.firestore, 'plans', clientId);

      // Sử dụng updateDoc để cập nhật tài liệu
      await updateDoc(docRef, { guide: guideArray });

      this.notifier.notify(
        'success',
        `Cập nhật hướng dẫn cho khách hàng thành công!`,
      );
      this.matchingTools(clientPlans);
    });

    const videoCallButton = GUI.Button.CreateSimpleButton(
      'videoCallButton',
      'Gọi video',
    );
    videoCallButton.width = '200px';
    videoCallButton.height = '50px';
    videoCallButton.top = '20px';
    videoCallButton.left = '-400px';
    videoCallButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    videoCallButton.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    videoCallButton.onPointerDownObservable.add(() => {
      this.initiateVideoCall(providerEmail, clientEmail);
      videoCallButton.isVisible = false;
    });

    inputGuideForClient.addControl(videoCallButton);
    inputGuideForClient.addControl(inputGuide);
    inputGuideForClient.addControl(saveGuideButton);
    inputGuideForClient.addControl(scrollMenu);
  }

  private async setupLocalVideo() {
    this.localStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    this.localVideoFrame = document.getElementById(
      'localVideo',
    ) as HTMLVideoElement;
    this.localVideoFrame.srcObject = this.localStream;
    this.localVideoFrame.muted = true;
    this.localVideoFrame
      .play()
      .catch((error) => console.error('Error playing local video:', error));

    this.setupPeerConnection();
  }

  private setupPeerConnection(): void {
    const configuration = {
      iceServers: [
        { urls: ['stun:stun.l.google.com:19302'] },
        { urls: ['stun:stun1.l.google.com:19302'] },
      ],
    };
    this.peerConnection = new RTCPeerConnection(configuration);

    this.localStream
      .getTracks()
      .forEach((track) =>
        this.peerConnection.addTrack(track, this.localStream),
      );

    this.peerConnection.ontrack = (event: RTCTrackEvent) => {
      if (event.streams && event.streams[0]) {
        this.setupRemoteVideo(event.streams[0]);
      }
    };
    this.handlePeerConnectionRespond();
  }

  private setupRemoteVideo(stream: MediaStream): void {
    this.remoteVideo = document.getElementById(
      'remoteVideo',
    ) as HTMLVideoElement;
    if (this.remoteVideo) {
      this.remoteVideo.srcObject = stream;
      this.remoteVideo.muted = false;
      this.remoteVideo
        .play()
        .catch((error) => console.error('Error playing remote video:', error));
    }
  }

  private handlePeerConnectionRespond() {
    this.peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        // Tạo docRef cho collection 'candidates'
        const candidatesCollectionRef = collection(
          this.firestore,
          'calls',
          this.callDocId,
          'candidates',
        );

        // Sử dụng addDoc để thêm candidate vào collection
        addDoc(candidatesCollectionRef, event.candidate.toJSON())
          .then(() => {
            console.log('Candidate added successfully');
          })
          .catch((error) => {
            console.error('Error adding candidate:', error);
          });
      }
    };

    this.peerConnection.onconnectionstatechange = (_event) => {
      if (
        this.peerConnection.connectionState === 'disconnected' ||
        this.peerConnection.connectionState === 'failed'
      ) {
        this.notifier.notify(
          'error',
          'Connection interrupted. Please try again.',
        );
        this.endVideoCall();
      }
    };

    this.peerConnection.onnegotiationneeded = async () => {
      try {
        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);
        // Send the offer to the remote peer
        // You'll need to implement this part based on your signaling mechanism
      } catch (error) {
        console.error('Error during negotiation: ', error);
      }
    };

    const callDocRef = doc(this.firestore, 'calls', this.callDocId);

    this.subscribeCallAnswer = onSnapshot(
      callDocRef,
      (doc) => {
        const data = doc.data() as CallData;
        if (data && data.answer) {
          const remoteDesc = new RTCSessionDescription(data.answer);
          this.peerConnection.setRemoteDescription(remoteDesc);
        }
      },
      (error) => {
        console.error('Error fetching call data:', error);
      },
    );

    const candidatesCollectionRef = collection(
      this.firestore,
      'calls',
      this.callDocId,
      'candidates',
    );

    this.callCandidateSubscription = onSnapshot(
      candidatesCollectionRef,
      (snapshot) => {
        const addedCandidates = snapshot
          .docChanges()
          .filter((change) => change.type === 'added');

        addedCandidates.forEach(async (change) => {
          const candidateData = change.doc.data();
          const candidate = new RTCIceCandidate(candidateData);
          await this.peerConnection.addIceCandidate(candidate);
        });
      },
      (error) => {
        console.error('Error fetching call candidates:', error);
      },
    );
  }

  async endVideoCall() {
    // Unsubscribe from call-related subscriptions if they exist
    if (this.callSubscription) {
      this.callSubscription.unsubscribe();
    }

    if (this.callCandidateSubscription) {
      this.callCandidateSubscription.unsubscribe();
    }

    if (this.subscribeCallAnswer) {
      this.subscribeCallAnswer.unsubscribe();
    }

    // Close the peer connection and nullify its events
    if (this.peerConnection) {
      this.peerConnection.onicecandidate = null;
      this.peerConnection.onicegatheringstatechange = null;
      this.peerConnection.onsignalingstatechange = null;
      this.peerConnection.ontrack = null;

      this.peerConnection.getTransceivers().forEach((transceiver) => {
        transceiver.stop();
      });

      this.peerConnection.close();
      this.peerConnection = null;
    }

    // Update the call status in Firestore
    try {
      const callDocRef = doc(this.firestore, 'calls', this.callDocId); // Tạo một tham chiếu đến tài liệu cuộc gọi
      await updateDoc(callDocRef, {
        status: 'ended',
      });
    } catch (error) {
      console.error('Error ending call:', error);
      this.notifier.notify('error', 'Lỗi khi kết thúc cuộc gọi');
      return; // Exit the function if updating Firestore fails
    }

    // Clean up video elements
    if (this.localVideoFrame) {
      this.localVideoFrame.srcObject = null;
      this.localVideoFrame = null;
    }

    if (this.remoteVideo) {
      this.remoteVideo.srcObject = null;
    }

    // Stop all local media tracks
    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => track.stop());
    }

    // Notify user of call end success
    this.notifier.notify('success', 'Cuộc gọi đã kết thúc');
  }

  private async initiateVideoCall(callerEmail: string, receiverEmail: string) {
    this.callDocId = `${callerEmail}_${receiverEmail}`;

    try {
      await this.setupLocalVideo();

      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);

      const callDocRef = doc(this.firestore, 'calls', this.callDocId); // Tạo tham chiếu đến tài liệu cuộc gọi
      await setDoc(callDocRef, {
        offer: offer,
        providerEmail: callerEmail,
        clientEmail: receiverEmail,
        status: 'incoming',
      });

      // Lắng nghe thay đổi trên tài liệu Firestore để nhận câu trả lời
      this.callSubscription = onSnapshot(callDocRef, async (snapshot) => {
        const callData = snapshot.data() as CallData;
        if (callData && callData.status === 'accepted' && callData.answer) {
          const answer = new RTCSessionDescription(callData.answer);
          await this.peerConnection.setRemoteDescription(answer);
        }
        if (callData && callData.status === 'rejected') {
          this.endVideoCall();
        }
        if (callData && callData.status === 'ended') {
          this.endVideoCall();
        }
      });
    } catch (error) {
      console.error('Error initializing video call:', error);
      this.notifier.notify(
        'error',
        'Unable to initialize call. Please check camera/microphone access and try again.',
      );
      this.endVideoCall();
    }
  }

  private async handleIncomingCall(docId: string, callData: CallData) {
    this.callDocId = docId;
    try {
      if (!callData || callData.status !== 'incoming') {
        throw new Error('Invalid call data');
      }

      const isAccepted = await this.incomingCallInform(callData.providerEmail);

      if (isAccepted) {
        await this.setupLocalVideo();

        const offer = new RTCSessionDescription(callData.offer);
        await this.peerConnection.setRemoteDescription(offer);

        const answer = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(answer);

        const callDocRef = doc(this.firestore, 'calls', docId); // Tạo tham chiếu đến tài liệu cuộc gọi
        await setDoc(
          callDocRef,
          {
            status: 'accepted',
            answer: answer,
          },
          { merge: true },
        ); // Sử dụng merge để cập nhật tài liệu mà không ghi đè toàn bộ

        await this.handleIncomingCandidates(docId);

        // Lắng nghe thay đổi trên tài liệu Firestore để nhận biết kết thúc cuộc gọi
        this.callSubscription = onSnapshot(callDocRef, async (snapshot) => {
          const callData = snapshot.data() as CallData;
          if (callData && callData.status === 'ended') {
            this.endVideoCall();
          }
        });
      } else {
        const callDocRef = doc(this.firestore, 'calls', docId);
        await setDoc(
          callDocRef,
          {
            status: 'rejected',
          },
          { merge: true },
        ); // Sử dụng merge để cập nhật trạng thái
      }
    } catch (error) {
      console.error('Error handling incoming call:', error);
    }
  }

  private incomingCallInform(caller: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const dialogUI = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');

      const dialog = new GUI.Rectangle();
      dialog.width = '350px';
      dialog.height = '250px';
      dialog.background = 'grey';
      dialog.top = '0px';
      dialog.left = '0px';

      const text = new GUI.TextBlock();
      text.text = `Cuộc gọi từ ${caller}`;
      text.color = 'white';
      text.top = '-50px';
      text.left = '0px';
      text.fontSize = 18;
      dialog.addControl(text);

      const acceptButton = GUI.Button.CreateSimpleButton(
        'acceptButton',
        'Accept',
      );
      acceptButton.width = '150px';
      acceptButton.height = '40px';
      acceptButton.color = 'white';
      acceptButton.background = 'green';
      acceptButton.onPointerUpObservable.add(() => {
        dialogUI.dispose();
        resolve(true);
      });

      const rejectButton = GUI.Button.CreateSimpleButton(
        'rejectButton',
        'Reject',
      );
      rejectButton.width = '150px';
      rejectButton.height = '40px';
      rejectButton.color = 'white';
      rejectButton.background = 'red';
      rejectButton.onPointerUpObservable.add(() => {
        dialogUI.dispose();
        resolve(false);
      });

      const buttonPanel = new GUI.StackPanel();
      buttonPanel.isVertical = false;
      buttonPanel.height = '50px';
      buttonPanel.top = '50px';
      buttonPanel.left = '0px';
      buttonPanel.addControl(acceptButton);
      buttonPanel.addControl(rejectButton);

      dialog.addControl(buttonPanel);
      dialogUI.addControl(dialog);
    });
  }

  private async handleIncomingCandidates(docId: string) {
    const candidatesCollectionRef = collection(
      this.firestore,
      'calls',
      docId,
      'candidates',
    );

    // Lắng nghe thay đổi trong tập hợp các ICE candidates
    this.unsubscribeCandidates = onSnapshot(
      candidatesCollectionRef,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const candidateData = change.doc.data();
            const candidate = new RTCIceCandidate(candidateData);
            this.peerConnection.addIceCandidate(candidate).catch((e) => {
              console.error('Error adding received ICE candidate:', e);
            });
          }
        });
      },
      (error) => {
        console.error('Error fetching ICE candidates:', error);
      },
    );
  }

  public unsubscribeFromCandidates() {
    if (this.unsubscribeCandidates) {
      this.unsubscribeCandidates();
      this.unsubscribeCandidates = undefined; // Đặt lại thuộc tính để tránh gọi lại
    }
  }

  async createMarketUI(_scene) {
    if (this.isMarketUIVisible) {
      console.log('Market UI is already visible.');
      return;
    }
    try {
      const productsRef = collection(this.firestore, 'products');
      const q = query(productsRef, where('classify', '!=', null));

      const querySnapshot = await getDocs(q);
      this.productsSnapshot = querySnapshot.docs.map(
        (doc) => doc.data() as Product,
      );
    } catch (error) {
      console.error('Error during Firestore operation:', error);
      return;
    }

    // Sửa lỗi ở đây
    const allProducts: Product[] = this.productsSnapshot.map(
      (doc: Product) => doc,
    );

    let forProductList: Product[];
    if (this.userData && Array.isArray(this.userData.property)) {
      if (this.userData.property.length === 0) {
        forProductList = allProducts;
      } else {
        const userProduct = this.userData.property
          .filter((p) => p?.propName)
          .map((p) => p.propName);

        forProductList = allProducts.filter(
          (product) =>
            product?.propName && !userProduct.includes(product.propName),
        );
      }
    } else {
      forProductList = allProducts;
    }

    if (forProductList.length === 0) {
      console.log('No products for sale');
      return;
    }

    const marketUI = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');
    const userProductList = new GUI.ScrollViewer();
    userProductList.width = '250px';
    userProductList.height = '400px';
    userProductList.color = 'transparent';
    userProductList.background = 'rgba(50,0,0,0.2)';
    userProductList.isVisible = true;
    userProductList.top = '-50px';
    userProductList.left = '300px';
    userProductList.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    userProductList.horizontalAlignment =
      GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;

    const stackPanel = new GUI.StackPanel();
    userProductList.addControl(stackPanel);

    try {
      forProductList.forEach((doc) => {
        const button = GUI.Button.CreateSimpleButton(
          'button' + doc.propName,
          doc.propName,
        );
        button.width = '250px';
        button.height = '50px';
        button.color = 'white';
        button.background = 'black';
        button.onPointerClickObservable.addOnce(() => {
          this.menuSound.currentTime = 0;
          this.menuSound.play();
          if (this.currentTip) {
            this.currentTip.isVisible = false;
            marketUI.removeControl(this.currentTip);
          }
          if (this.currentBuyButton) {
            this.currentBuyButton.isVisible = false;
            marketUI.removeControl(this.currentBuyButton);
          }
          if (this.currentCancelButton) {
            this.currentCancelButton.isVisible = false;
            marketUI.removeControl(this.currentCancelButton);
          }

          const propTip = new GUI.InputTextArea();
          propTip.width = '400px';
          propTip.height = '250px';
          propTip.text = `${doc.propTip}\nBenefit: ${doc.propBenefit}\nPrice: ${doc.propPrice}`;
          propTip.isVisible = true;
          propTip.color = 'white';
          propTip.background = 'rgba(50,0,0,0.2)';
          propTip.top = '-100px';
          propTip.left = '-300px';
          propTip.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
          propTip.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
          marketUI.addControl(propTip);

          const buyButton = GUI.Button.CreateSimpleButton('buyButton', 'Mua');
          buyButton.width = '200px';
          buyButton.height = '50px';
          buyButton.isVisible = true;
          buyButton.top = '60px';
          buyButton.left = '-400px';
          buyButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
          buyButton.horizontalAlignment =
            GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
          buyButton.onPointerDownObservable.addOnce(async () => {
            this.menuSound.currentTime = 0;
            this.menuSound.play();
            if (this.userData.diamonds < doc.propPrice) {
              console.log(
                "You don't have enough diamonds to buy this product.",
              );
              return;
            }
            try {
              this.userData.diamonds -= doc.propPrice;
              this.userData.property.push({
                propName: doc.propName,
                propTag: doc.propTag,
                classify: doc.classify,
              });
              await this.userDocSnapshot.ref.update({
                property: this.userData.property,
                diamonds: this.userData.diamonds,
              });
              buyButton.isVisible = false;
              cancelButton.isVisible = false;
              propTip.isVisible = false;
              userProductList.isVisible = false;
              this.isMarketUIVisible = false;
              await this.loadUserData();
            } catch (updateError) {
              console.error('Error updating user data:', updateError);
            }
            this.initBuildingScene();
          });
          marketUI.addControl(buyButton);

          const cancelButton = GUI.Button.CreateSimpleButton(
            'cancelButton',
            'Hủy',
          );
          cancelButton.width = '200px';
          cancelButton.height = '50px';
          cancelButton.isVisible = true;
          cancelButton.top = '60px';
          cancelButton.left = '-200px';
          cancelButton.verticalAlignment =
            GUI.Control.VERTICAL_ALIGNMENT_CENTER;
          cancelButton.horizontalAlignment =
            GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
          cancelButton.onPointerDownObservable.addOnce(() => {
            buyButton.isVisible = false;
            cancelButton.isVisible = false;
            propTip.isVisible = false;
            userProductList.isVisible = false;
            this.isMarketUIVisible = false;
          });
          marketUI.addControl(cancelButton);

          this.currentTip = propTip;
          this.currentBuyButton = buyButton;
          this.currentCancelButton = cancelButton;
        });
        stackPanel.addControl(button);
      });
    } catch (error) {
      console.error('Error creating scroll menu:', error);
    }

    marketUI.addControl(userProductList);
    this.isMarketUIVisible = true;
  }

  private matchingTools(
    clientPlans: { name: string; option: number; tag: string[] }[],
  ) {
    const matchingPlans = clientPlans.filter((plan) => {
      return plan.tag.some((tag) => this.matchingTags.includes(tag)); // So sánh tag với matchingTags
    });
    if (matchingPlans.length > 0) {
      console.log('Found matching plans:', matchingPlans);
      const matchingPlanCount = matchingPlans.length;
      this.userData.diamonds += matchingPlanCount;
      const isPerfectMatch = matchingPlanCount === clientPlans.length;
      if (isPerfectMatch) {
        this.userData.diamonds += 10;
      }
    }
    this.updateUserDiamonds(this.userData.diamonds);
  }

  private async updateUserDiamonds(newDiamonds: number) {
    try {
      await this.userDocSnapshot.ref.update({ diamonds: newDiamonds });
    } catch (error) {
      console.error('Error updating user diamonds:', error);
    }
  }

  private onResize = () => {
    if (this.gameEngine) {
      this.gameEngine.resize();
    }
    if (this.buildingEngine) {
      this.buildingEngine.resize();
    }
    this.adjustUI();
  };

  private adjustUI() {
    const guideContainer = document.getElementById('guideContainer');
    const buttons = document.querySelectorAll('button');

    if (guideContainer) {
      guideContainer.style.width = 'auto';
      guideContainer.style.height = 'auto';
    }

    buttons.forEach((button) => {
      (button as HTMLElement).style.width = '100%';
      (button as HTMLElement).style.bottom = '10px'; // Điều chỉnh khoảng cách từ đáy
    });

    const resultContainer = document.getElementById('resultContainer');
    if (resultContainer) {
      resultContainer.style.width = 'auto';
      resultContainer.style.height = 'auto';
    }
  }
  private preloadPickCoin() {
    const pickCoin = new Audio();
    pickCoin.src = '/assets/audio/pickCoin.mp3';
    pickCoin.load();
    this.pickCoin = pickCoin;
  }
  private preloadSceneBuildingSound() {
    const menuSound = new Audio();
    menuSound.src = '/assets/audio/menu.mp3';
    menuSound.load();
    this.menuSound = menuSound;

    const nurseTable = new Audio();
    nurseTable.src = '/assets/audio/bich.mp3';
    nurseTable.load();
    this.nurseTable = nurseTable;
  }
}
