import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Firestore, collection, query, where, collectionData } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-danh-sach-nha-cung-cap',
  templateUrl: './danh-sach-nha-cung-cap.component.html',
  styleUrls: ['./danh-sach-nha-cung-cap.component.css']
})
export class DanhSachNhaCungCapComponent implements OnInit {
  keywordFilter = ''; // Biến chứa từ khóa tìm kiếm
  dsNhaCungCap: any[] = []; // Danh sách nhà cung cấp
  danhSachNccOBS: Observable<any[]>; // Observable để nhận dữ liệu từ Firestore
  NhaCungCapId: any;

  @Output() emitterNhaCungCap = new EventEmitter<any>();

  constructor(private afs: Firestore) { }

  ngOnInit(): void {
    // Lấy dữ liệu từ Firestore với điều kiện lọc theo trường name khác null
    const nccRef = collection(this.afs, 'User');
    const q = query(nccRef, where('name', '!=', null));

    // Sử dụng collectionData để lấy dữ liệu dưới dạng Observable
    this.danhSachNccOBS = collectionData(q, { idField: 'id' });

    // Load dữ liệu khi Observable nhận được dữ liệu
    this.loadData();
  }

  loadData(): void {
    this.danhSachNccOBS.subscribe(ncc => {
      this.dsNhaCungCap = ncc;
      this.dsNhaCungCap = this.dsNhaCungCap.sort((a, b) =>
        a.name.localeCompare(b.name) // Sắp xếp theo tên nhà cung cấp
      );
    });
  }

  // Tìm kiếm nhà cung cấp dựa trên từ khóa trong name và email
  handleSearch(): void {
    const keyword = this.keywordFilter.toLowerCase(); // Chuyển từ khóa thành chữ thường
    let filteredData = this.dsNhaCungCap;

    if (keyword) {
      filteredData = this.dsNhaCungCap.filter(ncc => {
        // Lọc dựa trên name hoặc email khớp với từ khóa
        const nameMatch = ncc.name?.toLowerCase().includes(keyword);
        const emailMatch = ncc.email?.toLowerCase().includes(keyword);
        return nameMatch || emailMatch;
      });
    }

    this.dsNhaCungCap = filteredData; // Cập nhật danh sách hiển thị
  }

  // Reset bộ lọc và tải lại danh sách
  handleResetSearch(): void {
    this.keywordFilter = ''; // Xóa từ khóa tìm kiếm
    this.loadData(); // Tải lại dữ liệu từ Firestore
  }

  // Phát ra sự kiện khi chọn nhà cung cấp
  emitNhaCungCap(nhaCungCap: any): void {
    if (nhaCungCap) {
      this.NhaCungCapId = nhaCungCap.id;
      this.emitterNhaCungCap.emit(nhaCungCap);
    }
  }
}
