import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Timestamp } from 'firebase/firestore'; // Import Timestamp from Firestore in Firebase v9+
import { OpenAIService } from '../../services/openai.service'; // Import OpenAIService
import { Auth } from '@angular/fire/auth';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  DocumentData,
} from '@angular/fire/firestore';
import {
  Storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from '@angular/fire/storage';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../services/auth.service';

interface Problem {
  id: string;
  name: string;
  tag: string[];
  description: string[];
}

interface Plan {
  name: string;
  option: number;
}

interface SearchResult {
  status: string[];
  clientEmail: string;
  clientPhone: string;
  id: string;
  createdAt: Timestamp;
  plans: Plan[];
  guide: string[];
  lab: string[];
  history: string[];
  age: number | null;
  gender: string | null;
  fullName: string | null;
  address: string | null;
  underlying: string | null;
  medications: { brandName: string; quantity: number; use: string }[];
  providerEmail: string; // Add this line
  files: { url: string; name: string; type: string }[]; // Add this line if not already present
}

@Component({
  selector: 'app-welcome-customer',
  templateUrl: './welcome-customer.component.html',
  styleUrls: ['./welcome-customer.component.css'],
})
export class WelcomeCustomerComponent implements OnInit, AfterViewInit {
  matchingProblems: Problem[] = [];
  selectedOptions: { [key: string]: number } = {};
  searchResults: SearchResult[] = [];
  selectedDocument: SearchResult | null = null;
  guideInput: string = '';
  labInput: string = '';
  historyInput: string = '';
  medications: { brandName: string; quantity: number; use: string }[] = [];
  age: number | null = null;
  gender: string | null = null;
  fullName?: string = '';
  clientPhone: string = '';
  clientEmail: string = '';
  keyword: string = '';
  plans: Plan[];
  loginEmail: string = '';
  address?: string = '';
  underlying?: string = '';
  userData: DocumentData | undefined;
  providerName?: string = '';
  inUpdate: boolean = false;
  private unsubscribe: (() => void) | null = null;
  subcribePlans: SearchResult[];
  selectedStatuses: string[] = [];
  statusOptions = [
    { label: 'Tiếp nhận', value: '1' },
    { label: 'Đã phục vụ', value: '2' },
    { label: 'Đã thanh toán', value: '3' },
    { label: 'Theo dõi', value: '4' },
    { label: 'Đã hoàn thành', value: '5' },
  ];
  allProblems: Problem[];
  selectedFiles: File[] = [];
  currentViewedFile: { url: string; type: string; name: string } | null = null;
  loadingFileSelectedOther: boolean;
  loadingFileList: boolean = false;

  constructor(
    public notifier: NotifierService,
    public docModal: NgbModal,
    private firestore: Firestore, // Sử dụng Firestore mới
    private storage: Storage,
    public auth: Auth, // Đảm bảo bạn lấy dịch vụ Auth
    private openAIService: OpenAIService, // Inject OpenAIService
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loadUserData();
  }

  async loadUserData() {
    this.authService.getUserData().subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.providerName = userData.name;
        this.loginEmail = userData.email;

        // Gọi hàm loadUserPlans với email đã có
        this.loadUserPlans(this.loginEmail);
      } else {
        console.log('No user data available');
      }
    });
  }

  private loadUserPlans(userEmail: string) {
    const plansQuery = query(
      collection(this.firestore, 'plans'),
      where('providerEmail', '==', userEmail),
    );

    this.unsubscribe = onSnapshot(
      plansQuery,
      (plansSnapshot) => {
        this.subcribePlans = plansSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as SearchResult[];
      },
      (error) => {
        console.error('Error fetching plans:', error);
      },
    );
  }

  getOpenAIResponse() {
    const words =
      this.underlying + ' ' + this.historyInput + ' ' + this.labInput + ' ';

    if (!words || words.trim() === '') {
      console.error('No valid input for OpenAI API');
      return;
    }

    this.openAIService.getCompletion(words).subscribe({
      next: (response) => {
        if (response && response.choices && response.choices.length > 0) {
          this.guideInput = response.choices[0].message.content;
          console.log('OpenAI Response:', this.guideInput);
        } else {
          console.error('No response received from OpenAI');
        }
      },
      error: (error) => {
        console.error('Error fetching OpenAI response:', error);
      },
      complete: () => {
        console.log('Request completed');
      },
    });
  }

  addMedication() {
    this.medications.push({ brandName: '', quantity: 0, use: '' });
  }

  removeMedication(index: number) {
    this.medications.splice(index, 1);
  }

  // Hàm xử lý khi chọn file
  selectFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      this.selectedFiles = Array.from(target.files);
    }
  }

  async savePlans() {
    const planData: SearchResult = {
      plans: Object.entries(this.selectedOptions).map(([name, option]) => ({
        name,
        option,
      })),
      guide: this.guideInput
        .split('\n')
        .filter((guide: string) => guide.trim() !== ''),
      lab: this.labInput.split('\n').filter((lab: string) => lab.trim() !== ''),
      history: this.historyInput
        .split('\n')
        .filter((history: string) => history.trim() !== ''),
      createdAt: Timestamp.now(),
      medications: this.medications,
      age: this.age,
      gender: this.gender,
      fullName: this.fullName,
      clientEmail: this.clientEmail,
      clientPhone: this.clientPhone,
      providerEmail: this.loginEmail,
      address: this.address,
      underlying: this.underlying,
      status: this.selectedStatuses,
      files: [],
      id: '',
    };
    if (this.selectedFiles.length > 0) {
      for (const file of this.selectedFiles) {
        if (file.type.match(/image\/*|application\/pdf/) == null) {
          this.notifier.notify(
            'error',
            `File ${file.name} không phải file ảnh/file pdf`,
          );
          continue;
        }
        if (file.size > 10485760) {
          this.notifier.notify(
            'error',
            `File ${file.name} không được quá 10MB.`,
          );
          continue;
        }
        const filePath = `hospitalUpload/${file.name}`;
        const fileRef = ref(this.storage, filePath);
        const task = uploadBytesResumable(fileRef, file);
        try {
          const uploadTaskSnapshot = await task;
          const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
          planData.files.push({
            url: downloadURL,
            name: file.name,
            type: file.type,
          });
        } catch (error) {
          this.notifier.notify('error', `Upload file ${file.name} thất bại.`);
          console.error('Error uploading file:', error);
        }
      }
    }
    try {
      const docRef = await addDoc(
        collection(this.firestore, 'plans'),
        planData,
      );
      console.log('Document written with ID: ', docRef.id);
      this.notifier.notify('success', 'Lưu kế hoạch và các file thành công');
      this.reset();
    } catch (error) {
      console.error('Error adding document: ', error);
      this.notifier.notify('error', 'Lưu kế hoạch thất bại');
    }
  }

  async handleKeywordFilter() {
    console.log('Searching for keyword:', this.keyword); // Debugging
    this.selectedDocument = null;

    try {
      const results: SearchResult[] = [];
      const keywordLower = this.keyword
        ? this.keyword.toString().toLowerCase()
        : '';

      this.subcribePlans.forEach((data: SearchResult) => {
        const fullName = data.fullName
          ? data.fullName.toString().toLowerCase()
          : '';
        const clientPhone = data.clientPhone
          ? data.clientPhone.toString().toLowerCase()
          : '';
        const clientEmail = data.clientEmail
          ? data.clientEmail.toString().toLowerCase()
          : '';

        if (
          (fullName && fullName.includes(keywordLower)) ||
          (clientPhone && clientPhone.includes(keywordLower)) ||
          (clientEmail && clientEmail.includes(keywordLower))
        ) {
          results.push({
            ...data, // Thêm tất cả các trường dữ liệu từ tài liệu
          });
        }
      });

      this.searchResults = results.sort((a, b) => {
        const aTime = a.createdAt ? a.createdAt.toMillis() : 0;
        const bTime = b.createdAt ? b.createdAt.toMillis() : 0;
        return bTime - aTime;
      });
    } catch (error) {
      console.error('Error during search:', error);
    }
  }

  onStatusFilter() {
    if (this.selectedStatuses.length > 0) {
      this.searchResults = this.subcribePlans.filter(
        (result) =>
          result.status &&
          result.status.some((status) =>
            this.selectedStatuses.includes(status),
          ), // Kiểm tra nếu status tồn tại và nằm trong selectedStatuses
      );
    } else {
      this.searchResults = [...this.searchResults]; // Reset lại khi không có trạng thái nào được chọn
    }
    this.selectedStatuses = []; // Reset selectedStatuses
  }

  selectDocument(document: SearchResult) {
    this.selectedDocument = document;
    console.log('Selected document:', document);
  }

  deleteDocument(documentId: string) {
    if (confirm('Bạn có chắc chắn muốn xóa document này?')) {
      const documentRef = doc(collection(this.firestore, 'plans'), documentId);
      deleteDoc(documentRef)
        .then(() => {
          console.log('Document deleted successfully');
          this.searchResults = this.searchResults.filter(
            (result) => result.id !== documentId,
          );
          this.selectedDocument = null;
        })
        .catch((error) => {
          console.error('Error deleting document: ', error);
        });
    }
  }

  importSelectedDocumentPersonal() {
    if (this.selectedDocument) {
      this.fullName = this.selectedDocument.fullName || null;
      this.clientEmail = this.selectedDocument.clientEmail || null;
      this.address = this.selectedDocument.address || null;
      this.underlying = this.selectedDocument.underlying || null;
      this.age = this.selectedDocument.age || null;
      this.gender = this.selectedDocument.gender || null;
      this.clientPhone = this.selectedDocument.clientPhone || null;
    }
  }

  importSelectedDocumentLab() {
    if (this.selectedDocument) {
      this.labInput = (this.selectedDocument.lab || []).join('\n');
    }
  }

  importSelectedDocumentTreatment() {
    if (this.selectedDocument) {
      this.guideInput = (this.selectedDocument.guide || []).join('\n');
      this.medications = this.selectedDocument.medications || [];
    }
  }

  importAll() {
    if (this.selectedDocument) {
      this.fullName = this.selectedDocument.fullName || null;
      this.clientEmail = this.selectedDocument.clientEmail || null;
      this.address = this.selectedDocument.address || null;
      this.underlying = this.selectedDocument.underlying || null;
      this.age = this.selectedDocument.age || null;
      this.gender = this.selectedDocument.gender || null;
      this.clientPhone = this.selectedDocument.clientPhone || null;
      this.labInput = (this.selectedDocument.lab || []).join('\n');
      this.guideInput = (this.selectedDocument.guide || []).join('\n');
      this.medications = this.selectedDocument.medications || [];
    }
    this.inUpdate = true;
  }

  async updatePlan() {
    if (this.selectedDocument) {
      const documentId = this.selectedDocument.id; // Lấy id từ selectedDocument
      const updatedData = {
        fullName: this.fullName,
        clientEmail: this.clientEmail,
        address: this.address,
        underlying: this.underlying,
        age: this.age,
        gender: this.gender,
        clientPhone: this.clientPhone,
        status: this.selectedStatuses,
        lab: this.labInput
          .split('\n')
          .filter((lab: string) => lab.trim() !== ''), // Chuyển đổi lại thành mảng và loại bỏ trống
        guide: this.guideInput
          .split('\n')
          .filter((guide: string) => guide.trim() !== ''), // Chuyển đổi lại thành mảng và loại bỏ trống
        medications: this.medications,
        plans: Object.entries(this.selectedOptions).map(([name, option]) => ({
          name,
          option,
        })), // Nếu cần cập nhật plans
      };

      try {
        const documentRef = doc(
          collection(this.firestore, 'plans'),
          documentId,
        );
        await updateDoc(documentRef, updatedData);
        console.log('Document updated successfully');
        this.reset();
      } catch (error) {
        console.error('Error updating document:', error);
        // Thông báo lỗi cho người dùng
      }
    }
  }

  reset() {
    this.matchingProblems = [];
    this.selectedOptions = {};
    this.searchResults = [];
    this.selectedDocument = null;
    this.guideInput = '';
    this.labInput = '';
    this.historyInput = '';
    this.medications = [];
    this.age = null;
    this.gender = null;
    this.fullName = null;
    this.address = null;
    this.clientEmail = null;
    this.underlying = null;
    this.clientPhone = '';
    this.keyword = '';
    this.plans = [];
    this.inUpdate = false;
    this.selectedStatuses = [];
  }

  ngAfterViewInit(): void {
    this.loadUserPlans(this.loginEmail);
    this.getProblemsCollection();
  }

  getProblemsCollection(): void {
    const problemsQuery = collection(this.firestore, 'problems');

    getDocs(problemsQuery).then((problemsSnapshot) => {
      // Lưu toàn bộ problems vào biến allProblems
      this.allProblems = problemsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          name: data.name, // Giả sử bạn có thuộc tính này trong Firestore
          tag: data.tag, // Giả sử bạn có thuộc tính này trong Firestore
          description: data.description, // Giả sử bạn có thuộc tính này trong Firestore
          selectedOption: 1, // Giá trị option mặc định
        } as Problem;
      });
    });
  }

  searchProblems(): void {
    const words = (
      this.underlying +
      ' ' +
      this.historyInput +
      ' ' +
      this.labInput
    ).toLowerCase();

    // Sử dụng Set để loại bỏ trùng lặp
    const matchingIds = new Set<string>();

    this.allProblems.forEach((problem) => {
      if (
        problem.tag.some((tag: string) => words.includes(tag.toLowerCase()))
      ) {
        matchingIds.add(problem.id); // Thêm ID của document vào Set
      }
    });

    // Chuyển đổi Set thành mảng các document duy nhất
    this.matchingProblems = this.allProblems.filter((problem) =>
      matchingIds.has(problem.id),
    );
  }

  selectOption(problemName: string, option: number) {
    this.selectedOptions[problemName] = option;
    this.searchPlans();
  }

  async searchPlans() {
    this.selectedDocument = null;
    const plansArray = Object.entries(this.selectedOptions).map(
      ([name, option]) => ({ name, option }),
    );

    try {
      const plansQuery = query(
        collection(this.firestore, 'plans'),
        where('plans', '==', plansArray),
      );
      const querySnapshot = await getDocs(plansQuery);
      this.searchResults = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as SearchResult[];

      // Sắp xếp kết quả theo thời gian tạo (giảm dần)
      this.searchResults.sort((a, b) => {
        const aTime = a.createdAt ? a.createdAt.toMillis() : 0;
        const bTime = b.createdAt ? b.createdAt.toMillis() : 0;
        return bTime - aTime;
      });
    } catch (error) {
      console.error('Error during search:', error);
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribe) {
      this.unsubscribe(); // Gọi unsubscribe để hủy đăng ký
    }
  }

  trackByFn(index, item) {
    return item; // Assuming items are unique or have a unique identifier
  }

  viewFile(file: File) {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        this.currentViewedFile = {
          url: e.target.result as string,
          type: file.type.startsWith('image/') ? 'image' : 'other',
          name: file.name,
        };
      }
    };
    reader.readAsDataURL(file);
  }

  deleteFile(index: number) {
    this.selectedFiles.splice(index, 1);
    if (this.selectedFiles.length === 0) {
      this.currentViewedFile = null;
    }
  }
  isFileSelected(file: File): boolean {
    return this.currentViewedFile?.name === file.name;
  }
  contentLoaded() {
    this.loadingFileSelectedOther = false;
  }
}
