import { Injectable } from '@angular/core';
import {
  Auth,
  getAuth,
  signInWithEmailAndPassword,
  User,
} from '@angular/fire/auth';
import { CookieService } from 'ngx-cookie-service';
import { NotifierService } from 'angular-notifier';
import { GoService } from './go.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth;
  private userSubject: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = this.userSubject.asObservable(); // Observable để theo dõi người dùng
  private userDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );
  userData$: Observable<any> = this.userDataSubject.asObservable();

  constructor(
    private go: GoService,
    private notifier: NotifierService,
    private cookieSv: CookieService,
  ) {
    this.auth = getAuth();

    // Theo dõi thay đổi trạng thái người dùng
    this.auth.onAuthStateChanged((currentUser) => {
      this.userSubject.next(currentUser); // Cập nhật BehaviorSubject
    });
  }

  async signInWithEmail(email: string, password: string, isSave?: boolean) {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
      this.cookieManagement(email, password, isSave);
      this.notifier.notify('success', 'Đăng nhập thành công!');
      this.go.home();
    } catch (error) {
      this.showFailed(error);
    }
  }

  private cookieManagement(email: string, password: string, isSave?: boolean) {
    if (isSave) {
      this.cookieSv.set('email', email);
      this.cookieSv.set('password', password);
    } else {
      this.cookieSv.delete('email');
      this.cookieSv.delete('password');
    }
  }

  getCurrentUser(): Observable<User | null> {
    return this.user$; // Trả về Observable người dùng hiện tại
  }

  setUserData(data: any) {
    this.userDataSubject.next(data);
  }

  getUserData(): Observable<any> {
    return this.userData$;
  }

  async signOut() {
    console.log('[AuthService]', 'Sign Out!');
    try {
      await this.auth.signOut();
      this.cookieSv.delete('email');
      this.cookieSv.delete('password');
      this.go.login();
    } catch (error) {
      console.error('[AuthService]', 'Sign out failed', error);
    }
  }

  private showFailed(error: any) {
    console.log('[AuthService]', error.code);
    let message = '';
    let suggest = '';

    switch (error.code) {
      case 'auth/wrong-password':
        message = 'Sai mật khẩu!';
        suggest = 'Hãy thử lại với một mật khẩu khác.';
        break;
      case 'auth/user-not-found':
        message = 'Email này không có trong hệ thống!';
        suggest = 'Hãy sử dụng một email khác hoặc đăng ký mới.';
        break;
      case 'auth/popup-closed-by-user':
        message = 'Cửa sổ đăng nhập đã bị đóng khi chưa hoàn thành đăng nhập.';
        suggest =
          'Hãy thử quá trình đăng nhập lại hoặc sử dụng một tài khoản khác.';
        break;
      default:
        message = 'Đăng nhập thất bại!';
        suggest = 'Vui lòng thử lại bằng tài khoản khác.';
        break;
    }

    this.notifier.notify('error', message);
    this.notifier.notify('warning', suggest);
  }
}
