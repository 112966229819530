import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-my-account-detail',
  templateUrl: './my-account-detail.component.html',
  styleUrls: ['./my-account-detail.component.css']
})
export class MyAccountDetailComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

}
