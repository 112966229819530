<!-- Tìm kiếm theo tên hoặc số điện thoại -->
<div class="p-inputgroup">
  <input type="text" [(ngModel)]="keywordFilter" pInputText placeholder="Tên/Email/Số điện thoại" />
  <button type="button" pButton pRipple class="p-button-primary" icon="pi pi-search" (click)="handleSearch()">
  </button>
  <button type="button" pButton pRipple class="p-button-secondary" icon="pi pi-refresh" (click)="handleResetSearch()">
  </button>
</div>

<!-- Bảng hiển thị danh sách nhà cung cấp -->
<div class="container-ds-phien-kham">
  <table class="table table-bordered container table-sm" mat-table>
    <tbody>
      <tr *ngFor="let ncc of dsNhaCungCap; let i = index"
        class="data-row {{ NhaCungCapId === ncc.id ? 'active' : '' }}">
        <td style="cursor: pointer;" (click)="emitNhaCungCap(ncc)">
          <!-- Hiển thị tên nhà cung cấp -->
          <span>
            <small><b>
                {{ncc?.name}} <!-- Hiển thị tên nhà cung cấp -->
              </b></small>
          </span>
          <br>
          <!-- Hiển thị số điện thoại hoặc email -->
          <span>{{ncc?.email ? ncc.email : ncc?.phoneNumber}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>