import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GoService {

  constructor(
    private router: Router
  ) { }
  login() {
    console.log("[GoService]", "go login");
    this.router.navigate(["/dang-nhap"]);
  }
  home() {
    console.log("[GoService]", "go home");
    this.router.navigate(["/trang-chu"]);
  }
  registration() {
    console.log("[GoService]", "go home");
    this.router.navigate(["/dang-ky"]);
  }
}
