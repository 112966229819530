<nav ngbNav [(activeId)]="activeID" class="navbar navbar-expand-lg navbar-dark"
  style="background-color: rgb(51, 51, 76); padding:0; margin:0;">
  <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" data-toggle="collapse"
    data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarColor02" [ngbCollapse]="isCollapsed" *ngIf="csyt">
    <ul class="navbar-nav mr-auto" role="tablist"> <!-- Thêm vai trò tablist -->
      <li [ngbNavItem]="1" class="nav-item">
        <a class="nav-link" [routerLink]="['chao-mung']" role="tab">QUẢN LÝ HỒ SƠ</a> <!-- Vai trò tab -->
      </li>
      <li [ngbNavItem]="2" class="nav-item">
        <a class="nav-link" [routerLink]="['quan-ly-nguoi-dung']" role="tab">MEVATA</a> <!-- Vai trò tab -->
      </li>
      <li [ngbNavItem]="3" class="nav-item">
        <a class="nav-link" [routerLink]="['quan-ly-thanh-toan']" role="tab">...</a> <!-- Vai trò tab -->
      </li>
    </ul>
  </div>

  <button class="btn btn-outline-light my-2 my-sm-0" style="padding:0; margin:0"
    (click)="authSv.signOut()">&#10162;</button>
</nav>
<router-outlet></router-outlet>