import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { from, of } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { Firestore, doc, getDoc, setDoc } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css'],
})
export class RootComponent implements OnInit {
  isCollapsed = false;
  activeID = 2;
  csyt: boolean = false; // Khởi tạo mặc định là false

  constructor(
    public authSv: AuthService,
    private firestore: Firestore, // Sử dụng Firestore mới
  ) {}

  async ngOnInit() {
    this.checkUserRole(); // Gọi phương thức kiểm tra role và xử lý document Firestore
  }

  checkUserRole() {
    this.authSv.user$
      .pipe(
        switchMap((user) => {
          if (user) {
            const userDocRef = doc(this.firestore, `User/${user.uid}`);
            return getDoc(userDocRef).then((docSnap) => ({ docSnap, user }));
          } else {
            return of(null);
          }
        }),
        switchMap((result) => {
          if (result && result.docSnap.exists()) {
            const userData = result.docSnap.data();
            this.authSv.setUserData(userData);

            const role = userData.role;
            if (Array.isArray(role) && role.includes('csyt')) {
              this.csyt = true;
            }
            return of(null);
          } else if (result) {
            const userDocRef = doc(this.firestore, `User/${result.user.uid}`);
            const newUserData = {
              email: result.user.email || '',
              createdAt: new Date(),
              diamonds: 0,
            };
            this.authSv.setUserData(newUserData);
            return from(setDoc(userDocRef, newUserData)).pipe(first());
          } else {
            return of(null);
          }
        }),
      )
      .subscribe({
        next: () => {
          console.log('User role checked or document created');
        },
        error: (err) => {
          console.error('Error checking user role or creating document', err);
        },
      });
  }
}
