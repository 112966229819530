import { Component, OnInit, ViewChild } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';


import { NotifierService } from 'angular-notifier';
import { DanhSachNhaCungCapComponent } from './danh-sach-nha-cung-cap/danh-sach-nha-cung-cap.component';

@Component({
  selector: 'app-quan-ly-thanh-toan',
  templateUrl: './quan-ly-thanh-toan.component.html',
  styleUrls: ['./quan-ly-thanh-toan.component.css']
})
export class QuanLyThanhToanComponent implements OnInit {

  active = 1;
  nhaCungCapSelected: any;
  hoanTatThanhToan = false;
  Request: any[] = [];

  yearFilter = new Date().getFullYear();
  yearsFilter = Array.from(Array(10).keys()).map(y => { return { label: this.yearFilter - y, value: this.yearFilter - y } });
  monthFilter = new Date().getMonth() + 1;
  loading = false;

  @ViewChild('cmpDanhSachNhaCungCap') cmpDanhSachNhaCungCap: DanhSachNhaCungCapComponent;

  constructor(private afs: Firestore, private notifier: NotifierService) { }


  ngOnInit(): void {
    // console.log(this.yearsFilter);
  }

  onBackWard() {
    this.monthFilter = this.monthFilter - 1 === 0 ? 1 : this.monthFilter -= 1;
    if (this.cmpDanhSachNhaCungCap) {

    }
  }

  onForWard() {
    this.monthFilter = this.monthFilter + 1 > 12 ? 1 : this.monthFilter += 1;
    if (this.cmpDanhSachNhaCungCap) {

    }
  }

  onYearFilter(yearData: any) {
    if (yearData) {
      if (this.cmpDanhSachNhaCungCap) {

      }
    }
  }


  handlerNhaCungCap(nhaCungCapData: any) {
    this.nhaCungCapSelected = nhaCungCapData;

  }


}
